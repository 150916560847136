export const color = {
    "scale": {
        "white": "#FFFFFF",
        "black": "#000000",
        "alpha": {
            "0": "rgba(0, 0, 0, 0)",
            "10": "rgba(0,0,0,0.1)",
            "12": "rgba(0,0,0,0.12)",
            "02": "rgba(0,0,0,0.02)",
            "04": "rgba(0,0,0,0.04)",
            "06": "rgba(0,0,0,0.06)"
        },
        "blue": {
            "100": "#F2F8FD",
            "200": "#CDE4F7",
            "300": "#87BFEC",
            "400": "#449BE1",
            "500": "#1A77CA",
            "600": "#155EA0",
            "700": "#0F4778",
            "800": "#093051",
            "900": "#041A2D"
        },
        "green": {
            "100": "#EEFAF5",
            "200": "#B8E7D3",
            "300": "#5ECD9E",
            "400": "#00AB62",
            "500": "#00874D",
            "600": "#006A3D",
            "700": "#00502E",
            "800": "#00361F",
            "900": "#001E11"
        },
        "purple": {
            "100": "#F8F5FD",
            "200": "#E7DEF8",
            "300": "#C5AEEE",
            "400": "#A683E5",
            "500": "#895ED3",
            "600": "#7043B7",
            "700": "#572B97",
            "800": "#3B1D66",
            "900": "#21103A"
        },
        "orange": {
            "100": "#FFF5ED",
            "200": "#FFDABF",
            "300": "#FFA461",
            "400": "#EA7325",
            "500": "#C45317",
            "600": "#A13C14",
            "700": "#7E2812",
            "800": "#59170D",
            "900": "#340B07"
        },
        "yellow": {
            "100": "#FFF7D9",
            "200": "#FFDF62",
            "300": "#E5B203",
            "400": "#B98F02",
            "500": "#927002",
            "600": "#745801",
            "700": "#584201",
            "800": "#3B2C00",
            "900": "#201800"
        },
        "red": {
            "100": "#FFF3F3",
            "200": "#FFD8D8",
            "300": "#FF9F9F",
            "400": "#FF5D5D",
            "500": "#D63D3D",
            "600": "#AA3030",
            "700": "#802424",
            "800": "#571818",
            "900": "#310E0E"
        },
        "navy": {
            "100": "#f4f6f8",
            "200": "#dae2e9",
            "300": "#a9bbcb",
            "400": "#7C98B4",
            "500": "#557999",
            "600": "#3e607d",
            "700": "#2e475d",
            "800": "#1f303f",
            "900": "#111a23"
        },
        "slate": {
            "100": "#f5f6f8",
            "200": "#dee1e6",
            "300": "#b2b9c5",
            "400": "#8a96a7",
            "500": "#68778d",
            "600": "#515d70",
            "700": "#3d4653",
            "800": "#282f38",
            "900": "#16191f"
        },
        "grey": {
            "100": "#f7f7f7",
            "200": "#e1e1e1",
            "300": "#b9b9b9",
            "400": "#959595",
            "500": "#757575",
            "600": "#5c5c5c",
            "700": "#454545",
            "800": "#2e2e2e",
            "900": "#191919"
        }
    }
} as const;