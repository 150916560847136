import React from "react";
import { navigationBarItemStyles } from "./navigationBarStyles";

export interface NavigationBarLinkProps {
    label: string;
    href: string;
    isActive?: boolean;
}

export function NavigationBarLink({ label, href, isActive }: NavigationBarLinkProps) {
    return (
        <a className={navigationBarItemStyles} aria-current={isActive ? "page" : undefined} href={href}>
            {label}
        </a>
    );
}
