import type { UserPermissions, UserResource } from "@octopusdeploy/octopus-server-client";
import React from "react";

interface OctopusSessionContextValue {
    currentUser: UserResource | null;
    currentPermissions: UserPermissions;
}

const OctopusSessionContext = React.createContext<OctopusSessionContextValue | undefined>(undefined);

export interface OctopusSessionProviderProps extends OctopusSessionContextValue {
    children: React.ReactNode;
}

export function OctopusSessionProvider({ currentUser, currentPermissions, children }: OctopusSessionProviderProps) {
    const contextValue = React.useMemo(
        () => ({
            currentUser,
            currentPermissions,
        }),
        [currentUser, currentPermissions]
    );
    return <OctopusSessionContext.Provider value={contextValue}>{children}</OctopusSessionContext.Provider>;
}

export function useOctopusSessionUser() {
    const { currentUser } = useOctopusSessionContext();

    if (currentUser === null) {
        throw new Error("A user for the session is required but was null.");
    }
    return currentUser;
}

export function useOctopusSessionPermissions() {
    const { currentPermissions } = useOctopusSessionContext();

    if (currentPermissions === null) {
        throw new Error("Permissions for the session are required but was null.");
    }
    return currentPermissions;
}

function useOctopusSessionContext() {
    const contextValue = React.useContext(OctopusSessionContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusSessionProvider");
    }
    return contextValue;
}
