import * as globals from "./globals";
import type { OctopusTheme } from "./OctopusTheme";
export const lightTheme: OctopusTheme = {
    "shadowExtra-small": "0 1px 2px 0 rgba(0,0,0,0.06), 0 2px 8px 0 rgba(0,0,0,0.02)",
    "shadowSmall": "0 1px 1px 0 #0000000f, 0 1px 8px 0 rgba(0,0,0,0.12)",
    "shadowMedium": "0 0 4px 0 #0000000a, 0 6px 12px 0 #0000001a",
    "shadowLarge": "0 1px 6px 0 rgba(0,0,0,0.1), 0 14px 32px -4px #0000001f",
    "colorSurfaceCard": globals["color"]["scale"]["navy"]["100"],
    "colorSurfaceCode": globals["color"]["scale"]["grey"]["100"],
    "colorSurfaceTableHeader": globals["color"]["scale"]["slate"]["100"],
    "colorSurfaceTableBody": globals["color"]["scale"]["white"],
    "colorTextInterfacePrimary": globals["color"]["scale"]["navy"]["700"],
    "colorTextInterfaceSecondary": globals["color"]["scale"]["navy"]["600"],
    "colorTextButtonSolidPrimaryBase": globals["color"]["scale"]["white"],
    "colorTextButtonSolidPrimaryHover": globals["color"]["scale"]["white"],
    "colorTextButtonSolidPrimaryFocus": globals["color"]["scale"]["white"],
    "colorTextButtonSolidPrimaryActive": globals["color"]["scale"]["white"],
    "colorTextButtonSolidPrimaryDisabled": globals["color"]["scale"]["white"],
    "colorTextButtonSolidSecondaryBase": globals["color"]["scale"]["grey"]["900"],
    "colorTextButtonSolidSecondaryHover": globals["color"]["scale"]["grey"]["900"],
    "colorTextButtonSolidSecondaryFocus": globals["color"]["scale"]["grey"]["900"],
    "colorTextButtonSolidSecondaryActive": globals["color"]["scale"]["grey"]["900"],
    "colorTextButtonSolidSecondaryDisabled": globals["color"]["scale"]["slate"]["400"],
    "colorTextButtonGhostPrimaryBase": globals["color"]["scale"]["blue"]["500"],
    "colorTextButtonGhostPrimaryHover": globals["color"]["scale"]["blue"]["700"],
    "colorTextButtonGhostPrimaryFocus": globals["color"]["scale"]["blue"]["500"],
    "colorTextButtonGhostPrimaryActive": globals["color"]["scale"]["blue"]["700"],
    "colorTextButtonGhostPrimaryDisabled": globals["color"]["scale"]["blue"]["300"],
    "colorTextButtonGhostSecondaryBase": globals["color"]["scale"]["grey"]["900"],
    "colorTextButtonGhostSecondaryHover": globals["color"]["scale"]["grey"]["900"],
    "colorTextButtonGhostSecondaryFocus": globals["color"]["scale"]["grey"]["900"],
    "colorTextButtonGhostSecondaryActive": globals["color"]["scale"]["grey"]["900"],
    "colorTextButtonGhostSecondaryDisabled": globals["color"]["scale"]["slate"]["400"],
    "colorTextInput": globals["color"]["scale"]["grey"]["900"],
    "colorTextInputPlaceholder": globals["color"]["scale"]["grey"]["500"],
    "colorTextNavigationBarLinkBase": globals["color"]["scale"]["slate"]["100"],
    "colorTextNavigationBarLinkHover": globals["color"]["scale"]["slate"]["100"],
    "colorTextNavigationBarLinkFocus": globals["color"]["scale"]["slate"]["100"],
    "colorTextNavigationBarLinkActive": globals["color"]["scale"]["slate"]["100"],
    "colorTextNavigationBarLinkSelected": globals["color"]["scale"]["slate"]["100"],
    "colorBackgroundButtonSolidPrimaryBase": globals["color"]["scale"]["blue"]["500"],
    "colorBackgroundButtonSolidPrimaryHover": globals["color"]["scale"]["blue"]["600"],
    "colorBackgroundButtonSolidPrimaryFocus": globals["color"]["scale"]["blue"]["500"],
    "colorBackgroundButtonSolidPrimaryActive": globals["color"]["scale"]["blue"]["700"],
    "colorBackgroundButtonSolidPrimaryDisabled": globals["color"]["scale"]["blue"]["300"],
    "colorBackgroundButtonSolidSecondaryBase": globals["color"]["scale"]["slate"]["200"],
    "colorBackgroundButtonSolidSecondaryHover": globals["color"]["scale"]["slate"]["300"],
    "colorBackgroundButtonSolidSecondaryFocus": globals["color"]["scale"]["slate"]["200"],
    "colorBackgroundButtonSolidSecondaryActive": globals["color"]["scale"]["slate"]["400"],
    "colorBackgroundButtonSolidSecondaryDisabled": globals["color"]["scale"]["slate"]["200"],
    "colorBackgroundButtonGhostPrimaryBase": globals["color"]["scale"]["alpha"]["0"],
    "colorBackgroundButtonGhostPrimaryHover": globals["color"]["scale"]["blue"]["100"],
    "colorBackgroundButtonGhostPrimaryFocus": globals["color"]["scale"]["alpha"]["0"],
    "colorBackgroundButtonGhostPrimaryActive": globals["color"]["scale"]["blue"]["200"],
    "colorBackgroundButtonGhostPrimaryDisabled": globals["color"]["scale"]["alpha"]["0"],
    "colorBackgroundButtonGhostSecondaryBase": globals["color"]["scale"]["alpha"]["0"],
    "colorBackgroundButtonGhostSecondaryHover": globals["color"]["scale"]["slate"]["100"],
    "colorBackgroundButtonGhostSecondaryFocus": globals["color"]["scale"]["alpha"]["0"],
    "colorBackgroundButtonGhostSecondaryActive": globals["color"]["scale"]["slate"]["200"],
    "colorBackgroundButtonGhostSecondaryDisabled": globals["color"]["scale"]["alpha"]["0"],
    "colorBackgroundNavigationBarLinkBase": globals["color"]["scale"]["alpha"]["0"],
    "colorBackgroundNavigationBarLinkHover": globals["color"]["scale"]["blue"]["700"],
    "colorBackgroundNavigationBarLinkFocus": globals["color"]["scale"]["alpha"]["0"],
    "colorBackgroundNavigationBarLinkActive": globals["color"]["scale"]["blue"]["800"],
    "colorBackgroundNavigationBarLinkSelected": globals["color"]["scale"]["blue"]["800"],
    "colorOutlineButtonSolidPrimaryFocus": globals["color"]["scale"]["blue"]["400"],
    "colorOutlineButtonSolidSecondaryFocus": globals["color"]["scale"]["blue"]["400"],
    "colorOutlineButtonGhostPrimaryFocus": globals["color"]["scale"]["blue"]["400"],
    "colorOutlineButtonGhostSecondaryFocus": globals["color"]["scale"]["blue"]["400"],
    "colorOutlineNavigationBarLinkFocus": globals["color"]["scale"]["blue"]["400"],
    "colorOutlineInputFocus": globals["color"]["scale"]["blue"]["400"],
    "colorBorderInputBase": globals["color"]["scale"]["slate"]["300"],
    "colorBorderInputHover": globals["color"]["scale"]["slate"]["500"],
    "colorBorderTable": globals["color"]["scale"]["navy"]["200"]
};