import type { RouteArgs } from "@octopusdeploy/octopus-server-client";
import React from "react";

interface OctopusRoutingContextValue {
    Link: ReturnType<typeof React.forwardRef<LinkElement, LinkProps>>;
    resolvePath: (path: string, uriTemplateParameters?: RouteArgs) => string;
}

const OctopusRoutingContext = React.createContext<OctopusRoutingContextValue | undefined>(undefined);

export interface OctopusRoutingProviderProps extends OctopusRoutingContextValue {
    children: React.ReactNode;
}

export function OctopusRoutingProvider({ resolvePath, Link, children }: OctopusRoutingProviderProps) {
    const contextValue = React.useMemo(
        () => ({
            resolvePath,
            Link,
        }),
        [resolvePath, Link]
    );
    return <OctopusRoutingContext.Provider value={contextValue}>{children}</OctopusRoutingContext.Provider>;
}

export function useOctopusLinkComponent() {
    const contextValue = React.useContext(OctopusRoutingContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusRoutingProvider");
    }
    return contextValue.Link;
}

export function useOctopusPathResolver() {
    const contextValue = React.useContext(OctopusRoutingContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusRoutingProvider");
    }
    return contextValue.resolvePath;
}

export interface LinkElement {
    focus: () => void;
}

export interface LinkProps {
    href: string;
    children: React.ReactNode;
    className?: string;
    tabIndex?: number;
}
