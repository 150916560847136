import type { ResourceWithId } from ".";

export enum DefectStatus {
    Unresolved = "Unresolved",
    Resolved = "Resolved",
}

export interface DefectResource extends ResourceWithId {
    Description: string;
    Status?: DefectStatus;
}
