import { css } from "@emotion/css";
import { color } from "@octopusdeploy/design-system-component-tokens";
import React from "react";

export function OctopusIcon() {
    return (
        <svg className={octopusIconStyles} viewBox="0 0 34 33">
            <path d="M4.55829 23.7717C6.774 22.4003 9.34329 20.046 8.37686 17.2574C7.83472 15.7489 7.104 14.446 7.00972 12.8231C6.91543 11.4289 7.12757 10.0346 7.62257 8.73172C9.60257 3.58886 15.4719 1.16601 20.7754 2.72029C25.6783 4.16029 29.0726 9.69172 27.0454 14.5146C25.8669 17.3031 25.3483 19.4517 27.9647 21.6003C28.6719 22.1946 30.3926 23.0631 30.3926 24.1146C30.3926 25.486 27.6347 23.8174 27.3283 23.5889C27.6819 24.1831 31.1469 27.7031 28.9311 27.9546C26.904 28.1831 25.089 25.4174 23.8633 24.2517C21.8126 22.2631 22.1661 26.6746 22.1661 27.566C22.1426 29.006 21.1054 31.9089 19.2197 30.0117C17.664 28.4574 18.2533 25.9431 17.169 24.206C15.9904 22.3089 14.034 26.1031 13.539 26.8117C12.9969 27.5889 10.239 31.3603 9.15472 29.3489C8.259 27.726 9.67329 25.166 10.3804 23.6803C10.1211 24.206 8.32972 25.006 7.81115 25.2574C6.63258 25.8289 5.43043 26.1946 4.08686 26.1031C1.329 25.9203 3.45043 24.5031 4.55829 23.7717Z" />
        </svg>
    );
}

const octopusIconStyles = css({
    fill: color.scale.blue[500],
});
