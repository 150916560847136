import type { ClientEvent } from "../events/ClientEvent";

export interface ClientEventBus {
    dispatchEvent(event: ClientEvent): void;
    subscribe(eventHandler: EventHandler): Unsubscribe;
}

export type EventHandler = (event: ClientEvent) => void;
export type Unsubscribe = () => void;

export function createClientEventBus(): ClientEventBus {
    let subscribers: EventHandler[] = [];
    return {
        dispatchEvent(event: ClientEvent) {
            subscribers.forEach((s) => {
                s(event);
            });
        },
        subscribe(eventHandler: EventHandler): Unsubscribe {
            subscribers.push(eventHandler);
            return () => {
                subscribers = subscribers.filter((s) => s !== eventHandler);
            };
        },
    };
}
