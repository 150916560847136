import React from "react";

export function OctopusRoundTable() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="330" height="224" viewBox="0 0 496 470">
            <g fill="none" transform="translate(8 1)">
                <path fill="#0F2535" d="M244.79 209.34H449.1c-4.72-110.6-95.86-198.82-207.62-198.82a206.82 206.82 0 0 0-98 24.53l101.31 174.29z" />
                <path fill="#0F2535" d="M346.17 192.48a.64.64 0 0 1-.27-.07.6.6 0 0 1-.27-.34l-7.58-26.83a.57.57 0 0 1 .39-.7l24.56-6.95h.15a.68.68 0 0 1 .28.07.59.59 0 0 1 .26.34l7.59 26.84a.56.56 0 0 1-.39.69l-24.58 7-.14-.05z" />
                <path
                    fill="#0F2535"
                    d="m363.17 158.13 7.59 26.87-24.59 6.95-7.58-26.84 24.58-7v.02zm0-1.14a1 1 0 0 0-.3 0L338.28 164a1.12 1.12 0 0 0-.77 1.39l7.58 26.83c.083.289.277.533.54.68.165.092.351.14.54.14.103.015.207.015.31 0l24.58-6.95c.288-.08.532-.27.68-.53a1.17 1.17 0 0 0 .1-.86l-7.59-26.83a1.12 1.12 0 0 0-1.08-.82v-.06z"
                />
                <g transform="translate(338 158)">
                    <path stroke="#FFF" d="M37.41 22.09 31.17 1.03m3.12 10.53-5.53 1.64" />
                    <circle cx="34.29" cy="11.56" r="1.92" fill="#0F2535" stroke="#FFF" />
                    <path fill="#FFF" d="m20.92 17.65 3.12 11a.74.74 0 0 1-.5.9l-6 1.7a.72.72 0 0 1-.9-.5L9.42 5.19a.72.72 0 0 1 .5-.89l6-1.7a.72.72 0 0 1 .89.5L20 14.51l.92 3.14z" />
                    <circle cx="15.54" cy="12.67" r="1" fill="#0F2535" />
                    <circle cx="14.71" cy="9.74" r="1" fill="#0F2535" />
                    <circle cx="13.92" cy="6.96" r="1" fill="#0F2535" />
                    <path fill="#FFF" d="m12.38 20.07 3.11 11a.72.72 0 0 1-.5.89l-6 1.71a.73.73 0 0 1-.89-.5L.88 7.61a.72.72 0 0 1 .5-.9l6-1.7a.73.73 0 0 1 .89.5l3.22 11.38.89 3.18z" />
                    <circle cx="6.99" cy="15.09" r="1" fill="#0F2535" />
                    <circle cx="6.16" cy="12.15" r="1" fill="#0F2535" />
                    <circle cx="5.38" cy="9.38" r="1" fill="#0F2535" />
                    <path fill="#FFF" d="m29.46 15.24 3.12 11a.72.72 0 0 1-.5.89l-6 1.71a.72.72 0 0 1-.89-.5L17.96 2.78a.72.72 0 0 1 .5-.89l6-1.71a.72.72 0 0 1 .89.5l3.22 11.38.89 3.18z" />
                    <circle cx="24.08" cy="10.26" r="1" fill="#0F2535" />
                    <circle cx="23.25" cy="7.33" r="1" fill="#0F2535" />
                    <circle cx="22.46" cy="4.55" r="1" fill="#0F2535" />
                </g>
                <path fill="#FFF" d="M312.55 122.3c-.39-.4-.57-.3-.39.23l1.37 4.14a5 5 0 0 0 1 1.68L326 140.09a5.24 5.24 0 0 0 1.65 1.06l4.11 1.47c.52.19.63 0 .25-.38l-19.46-19.94z" />
                <path d="m320.47 131.29-10.74-11" />
                <path fill="#0F2535" d="m319.76 129.7-.2 3.81 1.41 1.44 3.8-.13z" />
                <path fill="#FFF" d="m333.89 142.24 15.88-15.48a.69.69 0 0 0 0-1L329.45 105a.68.68 0 0 0-1 0l-15.87 15.49 21.31 21.75z" />
                <path fill="#0F2535" d="m320.611 128.65 5.354-5.223 5.314 5.447-5.354 5.223z" />
                <path fill="#FFF" d="M286.91 109.8a2.86 2.86 0 0 1-.77-.1l-24.36-6.55a3 3 0 0 1-2.13-3.69l5.26-19.57a3 3 0 0 1 3.69-2.13L293 84.3a3 3 0 0 1 2.13 3.7l-5.26 19.57a3 3 0 0 1-2.96 2.23z" />
                <path
                    fill="#0F2535"
                    d="M267.82 78.17c.22.001.438.031.65.09l24.36 6.54a2.51 2.51 0 0 1 1.76 3.06l-5.26 19.58a2.51 2.51 0 0 1-2.41 1.85c-.22 0-.438-.03-.65-.09l-24.36-6.54a2.51 2.51 0 0 1-1.76-3.07L265.4 80a2.52 2.52 0 0 1 2.42-1.85v.02zm0-1.02a3.54 3.54 0 0 0-3.41 2.61l-5.26 19.58a3.53 3.53 0 0 0 2.49 4.32L286 110.2a3.54 3.54 0 0 0 4.33-2.5l5.26-19.57a3.54 3.54 0 0 0-.36-2.68 3.46 3.46 0 0 0-2.14-1.64l-24.36-6.55a3.5 3.5 0 0 0-.91-.12v.01z"
                />
                <path fill="#FFF" d="m260.78 97.22 29.22 7.84-.64 2.38a2.5 2.5 0 0 1-3.06 1.76l-24.36-6.54a2.5 2.5 0 0 1-1.76-3.07l.6-2.37z" />
                <path
                    fill="#0F2535"
                    d="m265.06 93.31 12.23 3.29a.517.517 0 0 1-.26 1l-12.24-3.29a.5.5 0 0 1-.35-.63.51.51 0 0 1 .62-.37zm1.03-3.83 12.23 3.29a.51.51 0 0 1 .126.931.5.5 0 0 1-.386.049l-12.24-3.28a.518.518 0 0 1 .27-1v.01zm1.03-3.83 12.23 3.29a.517.517 0 0 1-.26 1l-12.24-3.28a.518.518 0 0 1 .27-1v-.01zm1.03-3.83 12.23 3.29a.517.517 0 0 1-.26 1l-12.24-3.31a.51.51 0 0 1-.36-.62.52.52 0 0 1 .63-.36zm19.825 18.832-7.272-1.954 1.939-7.214 7.272 1.954z"
                />
                <ellipse cx="270.943" cy="102.41" fill="#0F2535" rx="1.05" ry="1.04" transform="rotate(-74.96 270.943 102.41)" />
                <ellipse cx="267.577" cy="101.511" fill="#0F2535" rx="1.05" ry="1.04" transform="rotate(-74.96 267.577 101.511)" />
                <ellipse cx="264.191" cy="100.599" fill="#0F2535" rx="1.05" ry="1.04" transform="rotate(-74.96 264.19 100.6)" />
                <path
                    fill="#FFF"
                    d="M224.79 100.88a4.27 4.27 0 1 1 3.22.53 4.11 4.11 0 0 1-3.22-.53zm-3.53-13.19a5.78 5.78 0 0 0 3.81-3.07l2.17-.51a3.88 3.88 0 0 1 2.38.14A2.3 2.3 0 0 1 231 86c.88 3.813.65 5.873-.69 6.18a2.93 2.93 0 0 1-.78-.17 11.18 11.18 0 0 0-1.74-.32 5.85 5.85 0 0 0-2 .09 6.55 6.55 0 0 0-2.06.88 8.67 8.67 0 0 0-.17-1.09 7.37 7.37 0 0 0-2.3-3.88zm-8.36 10.94a6.38 6.38 0 1 1 4.82.8 6.15 6.15 0 0 1-4.82-.8z"
                />
                <path
                    fill="#FFF"
                    d="M201.52 81.39a4.08 4.08 0 0 1 .47-3.34 4.51 4.51 0 0 1 2.88-1.86L219 72.91a4.51 4.51 0 0 1 3.41.4 4.15 4.15 0 0 1 1.89 2.79c.13.58.24 1.14.33 1.69.09.55.15 1.16.19 1.82.04.62.04 1.24 0 1.86a7.63 7.63 0 0 1-.33 1.74 5.57 5.57 0 0 1-.7 1.54 4.17 4.17 0 0 1-1.18 1.19 4.93 4.93 0 0 1-1.73.74 1.81 1.81 0 0 1-.78-.18l-1.37-.5a7.43 7.43 0 0 0-1.91-.37 7.15 7.15 0 0 0-2.27.16 7.25 7.25 0 0 0-2.11.85 7.59 7.59 0 0 0-1.55 1.18l-1 1c-.31.32-.51.49-.62.51a4.84 4.84 0 0 1-1.88.1 4.24 4.24 0 0 1-1.58-.55 5.46 5.46 0 0 1-1.31-1.08 8.45 8.45 0 0 1-1.07-1.41 16 16 0 0 1-.83-1.66 16.91 16.91 0 0 1-.64-1.71c-.16-.5-.31-1.02-.44-1.63zm4.61 23.82a4.27 4.27 0 1 1 3.22.53 4.11 4.11 0 0 1-3.22-.53zm-6.25-12.04a2.28 2.28 0 0 1 .46-2.13 3.88 3.88 0 0 1 2.08-1.17l2.17-.5a5.72 5.72 0 0 0 4.78 1.07 7.35 7.35 0 0 0-.35 4.46c.087.34.197.675.33 1a6.69 6.69 0 0 0-2.24.12 5.91 5.91 0 0 0-1.85.8c-.5.293-.976.627-1.42 1a3.31 3.31 0 0 1-.63.51c-1.34.373-2.45-1.347-3.33-5.16z"
                />
                <path fill="#2F93E0" d="M246 224.34 144.47 402.16a207 207 0 0 0 97 24c112.77 0 204.55-89.82 207.73-201.82H246z" />
                <path fill="#2F93E0" d="M229.41 352h-.14l-27-7a.56.56 0 0 1-.41-.68l6.38-24.74a.58.58 0 0 1 .55-.42.36.36 0 0 1 .14 0l27 7a.57.57 0 0 1 .4.69L230 351.58a.58.58 0 0 1-.26.35.53.53 0 0 1-.33.07z" />
                <path
                    fill="#2F93E0"
                    d="m208.79 319.74 27 7-6.38 24.73-27-7 6.38-24.74v.01zm0-1.13a1.12 1.12 0 0 0-1.09.84l-6.38 24.74a1.11 1.11 0 0 0 .8 1.36l27 7c.093.01.187.01.28 0a1.12 1.12 0 0 0 1.08-.85l6.4-24.7a1.13 1.13 0 0 0-.81-1.37l-27-7h-.28v-.02z"
                />
                <g transform="translate(202 319)">
                    <path stroke="#FFF" d="m2.28 31.96 21.34 5.21m-10.67-2.61 1.37-5.6" />
                    <circle cx="12.95" cy="34.56" r="1.92" fill="#2F93E0" stroke="#FFF" />
                    <path fill="#FFF" d="m14.42 19.94-11.1-2.86a.72.72 0 0 1-.52-.88l1.56-6.07a.72.72 0 0 1 .88-.52L31 16.24a.73.73 0 0 1 .52.89l-1.56 6.06a.72.72 0 0 1-.88.52l-11.46-2.95-3.2-.82z" />
                    <circle cx="21.43" cy="17.8" r="1" fill="#2F93E0" />
                    <circle cx="24.38" cy="18.56" r="1" fill="#2F93E0" />
                    <circle cx="27.17" cy="19.28" r="1" fill="#2F93E0" />
                    <path fill="#FFF" d="M16.63 11.35 5.53 8.48a.73.73 0 0 1-.52-.88l1.57-6.07a.73.73 0 0 1 .88-.52l25.72 6.64a.72.72 0 0 1 .52.88l-1.57 6.07a.73.73 0 0 1-.88.52l-11.45-3-3.17-.77z" />
                    <circle cx="23.65" cy="9.2" r="1" fill="#2F93E0" />
                    <circle cx="26.6" cy="9.97" r="1" fill="#2F93E0" />
                    <circle cx="29.39" cy="10.69" r="1" fill="#2F93E0" />
                    <path fill="#FFF" d="M12.2 28.54 1.1 25.67a.72.72 0 0 1-.52-.88l1.56-6.06a.73.73 0 0 1 .89-.52l25.71 6.63a.72.72 0 0 1 .52.88l-1.56 6.07a.72.72 0 0 1-.88.52l-11.45-3-3.17-.77z" />
                    <circle cx="19.21" cy="26.4" r="1" fill="#2F93E0" />
                    <circle cx="22.16" cy="27.16" r="1" fill="#2F93E0" />
                    <circle cx="24.96" cy="27.88" r="1" fill="#2F93E0" />
                </g>
                <path fill="#FFF" d="M285.87 325.76c.54-.13.53-.34 0-.45l-4.27-.91a5.38 5.38 0 0 0-2 0l-15.91 4a5.27 5.27 0 0 0-1.75.89l-3.34 2.81c-.42.36-.33.54.21.4l27.06-6.74z" />
                <path d="m274.11 328.07 14.91-3.73" />
                <path fill="#2F93E0" d="m275.84 328.27-3.19-2.1-1.96.49-1.8 3.35z" />
                <path fill="#FFF" d="m257.88 334.15 5.39 21.52a.67.67 0 0 0 .81.49l28.25-7.07a.68.68 0 0 0 .49-.82l-5.39-21.51-29.55 7.39z" />
                <path fill="#2F93E0" d="m268.979 331.356 7.382-1.848 1.816 7.256-7.382 1.848z" />
                <path fill="#FFF" d="M326.44 328.85a3 3 0 0 1-2.15-.89L310 313.56a3 3 0 0 1 0-4.27l18-17.74a3 3 0 0 1 2.12-.87 3 3 0 0 1 2.14.89L346.5 306a3 3 0 0 1 0 4.26L328.56 328a3 3 0 0 1-2.12.85z" />
                <path
                    fill="#2F93E0"
                    d="M330.1 291.19a2.51 2.51 0 0 1 1.78.74l14.26 14.4a2.53 2.53 0 0 1 0 3.54l-17.93 17.74a2.51 2.51 0 0 1-3.53 0l-14.28-14.42a2.5 2.5 0 0 1 0-3.53l17.92-17.75a2.5 2.5 0 0 1 1.76-.72h.02zm-.02-1a3.48 3.48 0 0 0-2.48 1l-17.93 17.75a3.53 3.53 0 0 0 0 5l14.26 14.4a3.49 3.49 0 0 0 2.51 1 3.53 3.53 0 0 0 2.48-1l17.92-17.75a3.52 3.52 0 0 0 0-5l-14.26-14.4a3.49 3.49 0 0 0-2.51-1h.01z"
                />
                <path fill="#FFF" d="m333.61 293.68-21.48 21.26-1.73-1.75a2.5 2.5 0 0 1 0-3.53l17.92-17.75a2.51 2.51 0 0 1 3.54 0l1.75 1.77z" />
                <path
                    fill="#2F93E0"
                    d="m334.83 299.34-9 8.91a.51.51 0 0 1-.868-.355.5.5 0 0 1 .148-.355l9-8.92a.52.52 0 0 1 .72 0 .51.51 0 0 1 0 .72zm2.79 2.82-9 8.91a.513.513 0 0 1-.72-.73l9-8.91a.52.52 0 0 1 .72 0 .51.51 0 0 1 0 .73zm2.79 2.84-9 8.91a.513.513 0 0 1-.72-.73l9-8.91a.52.52 0 0 1 .72 0 .51.51 0 0 1 0 .73zm2.79 2.8-9 8.91a.52.52 0 0 1-.72 0 .51.51 0 0 1 0-.72l9-8.91a.513.513 0 0 1 .72.73v-.01zm-26.262 7.666 5.351-5.297 5.255 5.308-5.35 5.298z"
                />
                <ellipse cx="324.011" cy="299.88" fill="#2F93E0" rx="1.04" ry="1.05" transform="rotate(-44.71 324.011 299.88)" />
                <ellipse cx="326.5" cy="297.41" fill="#2F93E0" rx="1.04" ry="1.05" transform="rotate(-44.71 326.5 297.41)" />
                <ellipse cx="328.982" cy="294.957" fill="#2F93E0" rx="1.04" ry="1.05" transform="rotate(-44.71 328.982 294.957)" />
                <path
                    fill="#FFF"
                    d="M348.57 260.74a4.26 4.26 0 1 1 .73 7.83 4 4 0 0 1-2.5-2.08 4.25 4.25 0 0 1 1.77-5.75zm13.18 3.59a5.78 5.78 0 0 0 .73 4.84l-.65 2.13a3.77 3.77 0 0 1-1.33 2 2.25 2.25 0 0 1-2.15.31c-3.74-1.16-5.407-2.393-5-3.7a2.9 2.9 0 0 1 .55-.59 11.58 11.58 0 0 0 1.15-1.34 5.89 5.89 0 0 0 .93-1.79 6.58 6.58 0 0 0 .28-2.22 7.23 7.23 0 0 0 5.49.36zm-5.24-12.73a6.38 6.38 0 1 1-3.12 3.76 6.17 6.17 0 0 1 3.12-3.76z"
                />
                <path
                    fill="#FFF"
                    d="M377.13 250.45a4.16 4.16 0 0 1 2.66 2.09 4.54 4.54 0 0 1 .15 3.42l-4.3 13.89a4.46 4.46 0 0 1-2.06 2.74 4.15 4.15 0 0 1-3.36.23c-.57-.18-1.11-.37-1.63-.57a14.8 14.8 0 0 1-1.66-.76 14.08 14.08 0 0 1-1.6-.95 8.51 8.51 0 0 1-1.33-1.16 5.56 5.56 0 0 1-1-1.39 4.4 4.4 0 0 1-.43-1.62 5.14 5.14 0 0 1 .23-1.87 2.2 2.2 0 0 1 .56-.57l1.12-.93a7.18 7.18 0 0 0 1.29-1.46 7.44 7.44 0 0 0 1-2 8.14 8.14 0 0 0 .09-4.18c-.15-.52-.29-1-.41-1.4a2 2 0 0 1-.13-.79 5 5 0 0 1 .87-1.67 4.24 4.24 0 0 1 1.27-1.1 5.87 5.87 0 0 1 1.59-.58 8.9 8.9 0 0 1 1.76-.21 15.84 15.84 0 0 1 3.65.43c.54.09 1.11.24 1.67.41zm-22.9-8.01a4.18 4.18 0 0 1 3.25-.3 4.08 4.08 0 0 1 2.5 2.08 4.26 4.26 0 0 1-5 6.06 4.26 4.26 0 0 1-.73-7.84h-.02zm13.55.66a2.3 2.3 0 0 1 1.61 1.47 3.84 3.84 0 0 1 0 2.39l-.65 2.13a5.73 5.73 0 0 0-3.34 3.58 7.28 7.28 0 0 0-3.67-2.54 7.73 7.73 0 0 0-1.07-.25 6.64 6.64 0 0 0 1-2 5.91 5.91 0 0 0 .24-2 10.74 10.74 0 0 0-.2-1.76 3.1 3.1 0 0 1-.12-.79c.42-1.33 2.42-1.33 6.2-.23z"
                />
                <path fill="#54AB54" d="M232.39 217.86 130.52 42.61C70.058 80.804 33.48 147.401 33.682 218.916c.203 71.516 37.16 137.904 97.838 175.754l100.87-176.81z" />
                <path fill="#54AB54" d="M232.39 217.86 130.52 42.61C70.058 80.804 33.48 147.401 33.682 218.916c.203 71.516 37.16 137.904 97.838 175.754l100.87-176.81z" />
                <path fill="#54AB54" d="M154.3 153.5a.54.54 0 0 1-.39-.16l-18.48-17.64a.56.56 0 0 1 0-.79l19.26-20.17a.55.55 0 0 1 .4-.18.59.59 0 0 1 .39.16l18.48 17.64a.57.57 0 0 1 0 .79l-19.26 20.17a.55.55 0 0 1-.4.18z" />
                <path
                    fill="#54AB54"
                    d="m155.07 115.13 18.48 17.64-19.25 20.17-18.48-17.64 19.25-20.17zm0-1.13a1.13 1.13 0 0 0-.81.35L135 134.52a1.09 1.09 0 0 0-.31.8c.004.298.126.582.34.79l18.48 17.64c.21.2.49.31.78.31.3-.007.583-.133.79-.35l19.25-20.17a1.12 1.12 0 0 0 0-1.59l-18.48-17.64a1.17 1.17 0 0 0-.78-.31z"
                />
                <g transform="translate(133 113)">
                    <path stroke="#FFF" d="M15.5.6.54 16.68m7.48-8.04 4.23 3.93" />
                    <circle cx="8.02" cy="8.64" r="1.92" fill="#54AB54" stroke="#FFF" />
                    <path fill="#FFF" d="m20.07 17.06 7.91-8.3a.74.74 0 0 1 1 0l4.53 4.33a.72.72 0 0 1 0 1L15.22 33.3a.72.72 0 0 1-1 0L9.67 29a.72.72 0 0 1 0-1l8.17-8.56 2.23-2.38z" />
                    <circle cx="18.51" cy="24.22" r="1" fill="#54AB54" />
                    <circle cx="16.41" cy="26.43" r="1" fill="#54AB54" />
                    <circle cx="14.42" cy="28.51" r="1" fill="#54AB54" />
                    <path fill="#FFF" d="m26.49 23.19 7.91-8.3a.74.74 0 0 1 1 0L40 19.2a.72.72 0 0 1 0 1L21.66 39.41a.72.72 0 0 1-1 0l-4.53-4.33a.72.72 0 0 1 0-1l8.17-8.56 2.19-2.33z" />
                    <circle cx="24.94" cy="30.35" r="1" fill="#54AB54" />
                    <circle cx="22.83" cy="32.56" r="1" fill="#54AB54" />
                    <circle cx="20.84" cy="34.64" r="1" fill="#54AB54" />
                    <path fill="#FFF" d="m13.65 10.93 7.91-8.3a.74.74 0 0 1 1 0l4.53 4.33a.72.72 0 0 1 0 1L8.8 27.17a.72.72 0 0 1-1 0l-4.53-4.33a.72.72 0 0 1 0-1l8.17-8.56 2.21-2.35z" />
                    <circle cx="12.09" cy="18.09" r="1" fill="#54AB54" />
                    <circle cx="9.99" cy="20.3" r="1" fill="#54AB54" />
                    <circle cx="8" cy="22.38" r="1" fill="#54AB54" />
                </g>
                <path fill="#FFF" d="M130.68 197.11c-.14.54 0 .63.4.22L134 194a5.25 5.25 0 0 0 .92-1.73l4.29-15.83a5 5 0 0 0 .07-2l-.82-4.29c-.1-.54-.3-.55-.45 0l-7.33 26.96z" />
                <path d="m134.41 185.72-4.02 14.83" />
                <path fill="#54AB54" d="m133.39 187.13 3.39-1.76.52-1.95-2.04-3.2z" />
                <path fill="#FFF" d="m137 168.59-21.4-5.8a.67.67 0 0 0-.83.47l-7.61 28.1a.67.67 0 0 0 .47.83l21.41 5.8 7.96-29.4z" />
                <path fill="#54AB54" d="m124.816 185.007 1.99-7.345 7.22 1.956-1.99 7.345z" />
                <path fill="#FFF" d="M117.57 259.8a3 3 0 0 1-2.91-2.21l-6.74-24.3a3 3 0 0 1 2.1-3.71l19.53-5.42c.26-.072.53-.108.8-.11a3 3 0 0 1 2.91 2.21l6.74 24.3a3 3 0 0 1-2.1 3.71l-19.53 5.42a2.71 2.71 0 0 1-.8.11z" />
                <path
                    fill="#54AB54"
                    d="M130.35 224.57a2.51 2.51 0 0 1 2.41 1.83l6.74 24.3a2.5 2.5 0 0 1-1.73 3.08l-19.53 5.42a2.57 2.57 0 0 1-.67.09 2.52 2.52 0 0 1-2.41-1.83l-6.74-24.31a2.49 2.49 0 0 1 1.74-3.07l19.52-5.42a2.25 2.25 0 0 1 .67-.09zm0-1a3.74 3.74 0 0 0-.94.13l-19.53 5.42a3.54 3.54 0 0 0-2.46 4.35l6.75 24.3a3.54 3.54 0 0 0 3.4 2.59 3.68 3.68 0 0 0 .94-.13l19.49-5.46a3.53 3.53 0 0 0 2.46-4.34l-6.75-24.31a3.54 3.54 0 0 0-3.4-2.59l.04.04z"
                />
                <path fill="#FFF" d="m135.39 254.43-8.08-29.12 2.37-.65a2.5 2.5 0 0 1 3.08 1.74l6.74 24.3a2.5 2.5 0 0 1-1.73 3.08l-2.38.65z" />
                <path
                    fill="#54AB54"
                    d="m129.86 252.74-3.39-12.21a.518.518 0 0 1 1-.27l3.38 12.2a.52.52 0 0 1-1 .28h.01zM126 253.8l-3.39-12.21a.5.5 0 0 1 .39-.59.49.49 0 0 1 .62.35l3.38 12.18a.5.5 0 0 1-.36.62.49.49 0 0 1-.64-.35zm-3.79 1.06-3.38-12.21a.49.49 0 0 1 .35-.62.51.51 0 0 1 .63.35l3.39 12.21a.518.518 0 0 1-1 .27h.01zm-3.82 1.06L115 243.71a.52.52 0 0 1 .36-.62.51.51 0 0 1 .63.35l3.38 12.21a.49.49 0 0 1-.35.62.51.51 0 0 1-.63-.35zm6.165-26.651 2.013 7.256-7.198 1.997-2.013-7.256z"
                />
                <ellipse cx="134.705" cy="243.051" fill="#54AB54" rx="1.05" ry="1.04" transform="rotate(-15.51 134.705 243.051)" />
                <ellipse cx="135.632" cy="246.42" fill="#54AB54" rx="1.05" ry="1.04" transform="rotate(-15.51 135.632 246.42)" />
                <ellipse cx="136.569" cy="249.786" fill="#54AB54" rx="1.05" ry="1.04" transform="rotate(-15.51 136.57 249.786)" />
                <path
                    fill="#FFF"
                    d="M156.84 283.57a4.25 4.25 0 1 1-1.17-3 4.12 4.12 0 0 1 1.17 3zm-9.56 9.75a5.76 5.76 0 0 0-4.58-1.72l-1.54-1.6a3.86 3.86 0 0 1-1.1-2.13 2.27 2.27 0 0 1 .79-2c2.833-2.7 4.723-3.553 5.67-2.56a3 3 0 0 1 .25.77 12 12 0 0 0 .6 1.66 6.14 6.14 0 0 0 1.11 1.68 6.68 6.68 0 0 0 1.8 1.33 10 10 0 0 0-.85.7 7.29 7.29 0 0 0-2.15 3.87z"
                />
                <path fill="#FFF" d="M161 295a6.39 6.39 0 0 1-6.54 6.23 6.22 6.22 0 0 1-4.47-2A6.39 6.39 0 1 1 161 295z" />
                <path
                    fill="#FFF"
                    d="M151.89 313.52a4.15 4.15 0 0 1-3.12 1.3 4.49 4.49 0 0 1-3.06-1.54l-10-10.53a4.45 4.45 0 0 1-1.39-3.13 4.15 4.15 0 0 1 1.44-3.05c.43-.41.86-.79 1.29-1.15.43-.36.92-.71 1.47-1.08a14.22 14.22 0 0 1 1.61-.92 7.9 7.9 0 0 1 1.67-.6 5.93 5.93 0 0 1 1.69-.19 4.52 4.52 0 0 1 1.62.42 4.94 4.94 0 0 1 1.52 1.11 2 2 0 0 1 .23.77c.08.43.17.9.26 1.43a7.74 7.74 0 0 0 .65 1.84 7.42 7.42 0 0 0 1.29 1.87 7.54 7.54 0 0 0 1.81 1.38 6.93 6.93 0 0 0 1.8.73l1.42.33a2 2 0 0 1 .75.27 5 5 0 0 1 1 1.58c.219.52.33 1.077.33 1.64a5.57 5.57 0 0 1-.26 1.67 8.2 8.2 0 0 1-.68 1.64 14 14 0 0 1-1 1.56c-.39.53-.78 1-1.15 1.42-.37.42-.77.83-1.19 1.23zm18.16-16.08a4.25 4.25 0 1 1-1.17-3 4.12 4.12 0 0 1 1.17 3zm-7.18 11.5a2.28 2.28 0 0 1-2.07.69 3.83 3.83 0 0 1-2.07-1.2l-1.54-1.61a5.73 5.73 0 0 0-1.5-4.66 7.41 7.41 0 0 0 4-2 9.08 9.08 0 0 0 .73-.82 6.73 6.73 0 0 0 1.25 1.87 5.73 5.73 0 0 0 1.62 1.18c.524.272 1.07.503 1.63.69.264.061.52.156.76.28.96 1.04.02 2.88-2.81 5.58z"
                />
                <circle cx="241.44" cy="218.34" r="68.91" fill="#FFF" />
                <path
                    fill="#2F93E0"
                    d="M252.22 264.53a6 6 0 0 1-4.14-2.19c-2.8-2.92-3.43-6.75-4-10.45-.46-2.83-.9-5.5-2.24-7.74-.75-1.24-1.4-1.24-1.61-1.24-2.33 0-5.65 5.56-7.07 7.93-.37.63-.68 1.13-.89 1.44-2.57 3.76-7.46 10.08-11.35 10.08a3.67 3.67 0 0 1-3.31-2.26c-2.27-4.31 0-10.33 2.11-15-1.29.78-2.83 1.56-4.31 2.28l-.76.37a22.46 22.46 0 0 1-10.33 2.73c-.45 0-.9 0-1.36-.05-1.86-.13-4.38-.51-4.82-2.21-.44-1.7 1.22-3.52 5.68-6.41l.17-.11c5.47-3.53 14.15-10.69 11.19-19.32-.44-1.27-.93-2.52-1.4-3.72-1.33-3.35-2.58-6.52-2.8-10.35a32 32 0 0 1 2-13c4.69-12.6 16.76-20.74 30.74-20.74a34.78 34.78 0 0 1 10 1.46 30.8 30.8 0 0 1 18.5 16.15 26.49 26.49 0 0 1 .93 21.66c-3.38 8.23-5.17 14.57 2.54 21.14.47.4 1.13.87 1.83 1.36 2.59 1.86 5.81 4.16 5.8 7.08a2.32 2.32 0 0 1-2.53 2.49 11.15 11.15 0 0 1-4.34-1.29c2.95 3.8 5.41 7.52 4.46 9.83a3.05 3.05 0 0 1-2.74 1.73 7.5 7.5 0 0 1-.82 0c-4.89 0-9.11-4.92-12.49-8.88-.95-1.12-1.85-2.17-2.65-3-1.17-1.17-1.82-1.35-2.11-1.35a.6.6 0 0 0-.46.22c-1.37 1.4-1.23 7.11-1.18 9.55v1.33c-.11 3.6-2.01 10.48-6.24 10.48z"
                />
                <path
                    fill="#FFF"
                    d="M243.64 175.41a34 34 0 0 1 9.74 1.43c14.84 4.46 25 21.68 18.89 36.69-3.54 8.66-5.13 15.35 2.78 22.09 2.14 1.83 7.35 4.57 7.33 7.81a1.49 1.49 0 0 1-1.69 1.66c-2.49 0-6.92-2.78-7.59-3.3 1.06 1.85 11.55 12.79 4.88 13.57h-.73c-5.85 0-11-8.06-14.55-11.6a4.29 4.29 0 0 0-2.7-1.59c-3.15 0-2.43 9.61-2.45 11.93 0 3.42-1.9 9.55-5.33 9.55a5.14 5.14 0 0 1-3.54-1.93c-4.67-4.87-2.91-12.63-6.16-18a2.77 2.77 0 0 0-2.32-1.64c-3.4 0-7.46 8-8.65 9.73-1.31 1.92-6.8 9.71-10.66 9.71a2.86 2.86 0 0 1-2.57-1.81c-2.67-5.07 1.6-13 3.71-17.6-.77 1.66-6.2 4.12-7.79 4.92a21.72 21.72 0 0 1-10 2.65c-.43 0-.86 0-1.3-.05-8.2-.59-2.16-4.87 1.27-7.1l.52-.34-.34.22c6.65-4.28 14.46-11.62 11.5-20.27-1.62-4.72-3.86-8.75-4.16-13.85a31.13 31.13 0 0 1 1.9-12.68c4.76-12.79 17-20.2 30-20.2h.01zm-39.21 67-.18.12-.12.07.3-.19zm39.2-68.67c-14.33 0-26.71 8.36-31.53 21.29a33 33 0 0 0-2 13.36c.23 4 1.56 7.34 2.85 10.61.47 1.19 1 2.42 1.39 3.68 2.77 8.09-5.59 15-10.82 18.33l-.08.05-.09.06c-4.09 2.66-6.68 4.91-6.06 7.33.57 2.23 3.45 2.69 5.57 2.84.47 0 .95.06 1.42.06a23.27 23.27 0 0 0 10.72-2.82l.74-.37c.8-.39 1.53-.76 2.21-1.11-1.8 4.39-3.18 9.5-1.1 13.44a4.44 4.44 0 0 0 4 2.7c2.24 0 6.14-1.81 12-10.44.24-.34.54-.84.92-1.48 3-5 5.12-7.52 6.35-7.52.34 0 .7.52.89.83 1.26 2.1 1.68 4.69 2.13 7.44.63 3.83 1.27 7.79 4.26 10.9a6.75 6.75 0 0 0 4.74 2.44c4.87 0 7-7.3 7-11.21v-1.36c-.16-6.63.45-8.43.88-8.89a4.25 4.25 0 0 1 1.44 1.08c.77.77 1.66 1.81 2.6 2.91 3.5 4.09 7.85 9.18 13.13 9.18.3 0 .61 0 .92-.05a3.81 3.81 0 0 0 3.41-2.25c.9-2.18-.54-5.11-2.82-8.32a7.3 7.3 0 0 0 1.93.31 3.14 3.14 0 0 0 3.36-3.32c0-3.36-3.4-5.8-6.15-7.77-.68-.48-1.33-.94-1.77-1.32-6.67-5.68-6.06-11-2.31-20.18a27.3 27.3 0 0 0-.95-22.34 31.66 31.66 0 0 0-19-16.59 35.76 35.76 0 0 0-10.22-1.5h.04z"
                />
                <path
                    fill="#E9EDF2"
                    d="M49.46 287.3c-5.36-21.3-28-29.73-32.26-29.13a16.64 16.64 0 0 0-4.2 1.23 16.72 16.72 0 0 0-4 2.87 16.29 16.29 0 0 0-4.26 15.63l9.82 38.28A15.74 15.74 0 0 0 29 328a15.06 15.06 0 0 0 2.71-.13 17.71 17.71 0 0 0 4.78-1.53c2.92-1.41 18.18-18.34 12.97-39.04z"
                />
                <rect width="39.89" height="49.26" x="8.933" y="267.242" fill="#FDD0A2" rx="18.89" transform="rotate(75.62 28.878 291.872)" />
                <path
                    fill="#FDD0A2"
                    d="M53.2 289.42a3.68 3.68 0 0 1-1.81-7.07 3.68 3.68 0 0 1 1.81 7.07zM75 257.29a1.12 1.12 0 0 0 1 1.31l10.27 1.67a1.21 1.21 0 0 0 1.39-.93 1.12 1.12 0 0 0-.94-1.31l-10.26-1.67a1.22 1.22 0 0 0-1.46.93zm1.85-8.93-.09.43a.6.6 0 0 0 .51.71l7.65 1.25a.67.67 0 0 0 .76-.51l.09-.43a.61.61 0 0 0-.51-.71l-7.66-1.25a.66.66 0 0 0-.75.51zm0 6.03a1.11 1.11 0 0 0 .94 1.31l10.27 1.68a1.22 1.22 0 0 0 1.39-.93 1.11 1.11 0 0 0-.94-1.31l-10.26-1.68a1.24 1.24 0 0 0-1.4.93zm-.56-3.28v.1a1.06 1.06 0 0 0 .89 1.25l10.37 1.7a1.17 1.17 0 0 0 1.34-.89v-.1a1.06 1.06 0 0 0-.9-1.25l-10.37-1.7a1.17 1.17 0 0 0-1.33.89z"
                />
                <path fill="#FDD0A2" d="M77.84 247.94c.92.25 2.73.8 1.86 6.42-.87 5.62-4.52 4.47-4.52 4.47v1.18s3.51.63 1.91 3.76l-6.3-3.8-3.25-4 1.56-7.67 4.8-1s3.03.39 3.94.64z" />
                <path fill="#FDD0A2" d="M61.23 251c-.45 2.17.54 4.16 2.19 4.43l4.52.74c1.65.27 3.36-1.29 3.8-3.46.44-2.17-.55-4.16-2.2-4.43L65 247.5c-1.62-.27-3.33 1.29-3.77 3.5z" />
                <path
                    fill="#E9EDF2"
                    d="M13 269.39c-1.92-1.27-2.15-3.68-2.22-4.44-.15-1.57 0-5.05 9.06-14.41 10.12-10.42 14-10.31 15.26-10.19a9.17 9.17 0 0 1 4 1.4 7.54 7.54 0 0 1 1.69 3.71c.42 3.2-1.65 6.16-7.6 12.09-3 3-7.17 6.91-12.36 11.16-2.83 1.68-5.91 1.98-7.83.68z"
                />
                <path
                    fill="#E9EDF2"
                    d="M28.22 245.33a8.53 8.53 0 0 1 2.37-3.46c2.65-2.28 6.12-2.18 20.23.23 10.81 1.85 14.23 2.73 15.85 5.74a7.78 7.78 0 0 1 .84 4 8.38 8.38 0 0 1-2.37 3.45c-2.67 2.26-6.08 2-18.16 0-12.84-2.08-16.29-2.93-17.92-6a7.74 7.74 0 0 1-.84-3.96z"
                />
                <path
                    fill="#403528"
                    d="M45 308.11a30.74 30.74 0 0 0 2.33-20.32 30 30 0 0 0-1.52-4.61 8.07 8.07 0 0 0-8.91-4.76 16.43 16.43 0 0 0-2.55.7 28.5 28.5 0 0 1 5.35-2.37 1.27 1.27 0 0 0 .64-2.06 31.54 31.54 0 0 0-5.42-4.78 20.72 20.72 0 0 0-5.81.64l-10.51 2.7C7.94 276 1.34 286.52 3.94 296.65l.78 3.08c2.6 10.13 13.46 16.19 24.13 13.45l10.51-2.69a21.06 21.06 0 0 0 5.64-2.38z"
                />
                <path fill="#E9EDF2" d="m53.59 254.21 1.93-9.37a3.85 3.85 0 0 1 4.16-3.2l6.48 1.05a3.77 3.77 0 0 1 2.84 4.68l-1.92 9.36a3.86 3.86 0 0 1-4.16 3.21l-6.49-1.05a3.78 3.78 0 0 1-2.84-4.68z" />
                <path
                    fill="#FDD0A2"
                    d="M66.79 288.19a1.11 1.11 0 0 1-.67-1.47l3.64-9.72a1.21 1.21 0 0 1 1.49-.75 1.13 1.13 0 0 1 .68 1.47l-3.64 9.74c-.23.6-.886.92-1.5.73zm8.65 2.81-.44-.1a.6.6 0 0 1-.36-.79l2.72-7.26a.66.66 0 0 1 .81-.41l.41.14a.6.6 0 0 1 .37.79l-2.72 7.27a.65.65 0 0 1-.79.36zm-5.24-3a1.11 1.11 0 0 1-.67-1.46l3.64-9.75a1.24 1.24 0 0 1 1.5-.75 1.11 1.11 0 0 1 .67 1.47l-3.64 9.74a1.22 1.22 0 0 1-1.5.75zm2.57 2.16h-.1a1.07 1.07 0 0 1-.64-1.4l3.68-9.84a1.17 1.17 0 0 1 1.43-.72h.1a1.06 1.06 0 0 1 .64 1.4l-3.67 9.85a1.17 1.17 0 0 1-1.44.71z"
                />
                <path fill="#FDD0A2" d="M76.3 290.39c.23-.92.65-2.77-4.66-4.8-5.31-2.03-6.12 1.7-6.12 1.7l-1-.63s1.2-3.37-2.32-3.53l.18 7.36 1.89 4.82 7.43 2.45 3.22-3.69s1.15-2.77 1.38-3.68z" />
                <path fill="#FDD0A2" d="M65.43 303.32c-2.1-.7-3.34-2.55-2.76-4.11l1.6-4.29c.59-1.56 2.79-2.28 4.89-1.58 2.1.69 3.35 2.54 2.76 4.1l-1.6 4.29c-.58 1.57-2.78 2.27-4.89 1.59z" />
                <path
                    fill="#E9EDF2"
                    d="M25.74 319.14c-1.07 2-.11 4.26.19 5 .62 1.45 2.4 4.43 14.87 8.26 13.89 4.27 17.28 2.28 18.29 1.59a9.17 9.17 0 0 0 2.81-3.14 7.46 7.46 0 0 0-.3-4.07c-1.17-3-4.41-4.6-12.49-6.94-4.13-1.19-9.6-2.6-16.19-3.83-3.29-.14-6.1 1.06-7.18 3.13z"
                />
                <path
                    fill="#E9EDF2"
                    d="M53.94 334.77a8.58 8.58 0 0 0 4.18-.35c3.3-1.17 4.93-4.23 9.84-17.68 3.76-10.3 4.69-13.7 2.88-16.6a7.72 7.72 0 0 0-3-2.71 8.34 8.34 0 0 0-4.18.35c-3.28 1.19-4.72 4.29-9 15.75-4.58 12.18-5.54 15.59-3.7 18.53a7.81 7.81 0 0 0 2.98 2.71z"
                />
                <path fill="#E9EDF2" d="m58.82 308.61 9.81 2.64c2.16.58 4.4-.42 5-2.22l2-6.26c.62-2-.8-4.08-3.16-4.72l-9.8-2.63c-2.17-.59-4.4.41-5 2.21l-2 6.27c-.62 1.96.8 4.1 3.15 4.71z" />
                <path fill="#0F2535" d="M238 435.08c-18.23-1.64-38 6.81-38.46 14.45-.09 1.66.81 3.08 1.64 4.55a16.8 16.8 0 0 0 14.06 8.32l40.31 1.6c1.58-.11 11.39-.91 14.14-7.28a8.78 8.78 0 0 0 .68-3.2c-.11-7.65-16.37-17-32.37-18.44z" />
                <path fill="#FDD0A2" d="M211.13 392.53s5.8-6 8.43-7.3c2.59 4.63-2.63 11.16-2.63 11.16l-5.8-3.86z" />
                <rect width="40.75" height="47.8" x="215.561" y="419.997" fill="#FDD0A2" rx="18.81" transform="rotate(2.29 235.936 443.897)" />
                <path fill="#FDD0A2" d="M240.24 420.15a3.4 3.4 0 1 1-3.23-2.91 3.09 3.09 0 0 1 3.23 2.91z" />
                <path fill="#261F18" d="m242.49 437.48-13.25-.48a5.57 5.57 0 0 1-5.32-5.77 5.56 5.56 0 0 1 5.77-5.32l13.24.53a5.57 5.57 0 0 1 5.33 5.76 5.58 5.58 0 0 1-5.77 5.28z" />
                <path
                    fill="#261F18"
                    d="M256.48 438.19a7 7 0 0 0-1.05-3.13 5.83 5.83 0 0 0-4.23-2.13 7.2 7.2 0 0 0-8.23-6.5 9.34 9.34 0 0 0-13-.53 7.68 7.68 0 0 0-8.54 5.84 5.61 5.61 0 0 0-3.92 1.51A5.44 5.44 0 0 0 216 436a18 18 0 0 0-.19 2l-.41 10.18c-.378 10.35 7.655 19.07 18 19.54l3.14.13c10.35.378 19.07-7.655 19.54-18l.41-10.18a9 9 0 0 0-.01-1.48z"
                />
                <path fill="#FDD0A2" d="M258.26 400.33a1 1 0 0 1-1.41-.26l-5.06-7.4a1 1 0 0 1 .27-1.41 1 1 0 0 1 1.41.26l5.06 7.4a1 1 0 0 1-.27 1.41z" />
                <rect width="1.49" height="7.78" x="263.506" y="391.244" fill="#FDD0A2" rx=".55" transform="rotate(-34.35 264.25 395.134)" />
                <path fill="#FDD0A2" d="M262.53 401.39a1 1 0 0 1-1.41-.27l-5.06-7.39a1 1 0 0 1 .27-1.41 1 1 0 0 1 1.41.26l5.06 7.42a1 1 0 0 1-.27 1.39z" />
                <rect width="2.04" height="10.99" x="260.972" y="390.273" fill="#FDD0A2" rx=".97" transform="rotate(-34.4 261.992 395.768)" />
                <path fill="#FDD0A2" d="M265 394.83c-.54-.62-1.64-1.84-5.95.83-4.31 2.67-2.06 5.1-2.06 5.1l-1 .39s-1.77-2.52-3.78-.16l5.39 3.55 4.48 1.1 5.75-3.93-.93-4.08s-1.42-2.18-1.9-2.8z" />
                <path
                    fill="#FDD0A2"
                    d="M268.42 408.83c-1.63 1.11-3.63 1.05-4.44-.14l-2.22-3.25c-.81-1.19-.15-3.07 1.48-4.18 1.63-1.12 3.62-1.06 4.44.13l2.22 3.26c.81 1.18.15 3.06-1.48 4.18zm-64.04-3.26a2.77 2.77 0 0 0 3.87-.48l2.5-3.28a2.77 2.77 0 0 0-.55-3.86 2.76 2.76 0 0 0-3.87.49l-2.5 3.27a2.77 2.77 0 0 0 .55 3.86z"
                />
                <path fill="#FDD0A2" d="m218.05 396.9-1.13-.5s2.77-2.68-2.09-6.06c-.3-.2-.54-.34-.81-.51-1.48.63-6.42 6.89-7.75 8.6l4.41 3.35 5.28-1 6.5-3.81c-2.19-2.89-4.41-.07-4.41-.07z" />
                <path fill="#0F2535" d="M206.49 452.8a5.85 5.85 0 0 1-7.77-2.85L189 429a5.85 5.85 0 0 1 2.84-7.77 5.86 5.86 0 0 1 7.78 2.84l9.7 20.92a5.85 5.85 0 0 1-2.83 7.81z" />
                <path fill="#0F2535" d="M208.59 401.66a5.85 5.85 0 0 1 2.19 8l-11.41 20a5.85 5.85 0 0 1-8 2.19 5.85 5.85 0 0 1-2.19-8l11.4-20a5.86 5.86 0 0 1 8.01-2.19z" />
                <path
                    fill="#0F2535"
                    d="m210.26 408.59-8.68-4.42a3.5 3.5 0 0 1-1.53-4.69 3.51 3.51 0 0 1 4.69-1.53l8.68 4.42a3.5 3.5 0 0 1 1.53 4.69 3.5 3.5 0 0 1-4.69 1.53zm51.6 49.21a5.85 5.85 0 0 0 8-2.22l11.34-20.07a5.85 5.85 0 0 0-2.22-8 5.84 5.84 0 0 0-8 2.22l-11.34 20.07a5.85 5.85 0 0 0 2.22 8z"
                />
                <path fill="#0F2535" d="M263.85 406.66a5.85 5.85 0 0 0-2.82 7.78l9.77 20.88a5.85 5.85 0 0 0 10.802-.486 5.85 5.85 0 0 0-.202-4.474l-9.77-20.88a5.85 5.85 0 0 0-7.78-2.82z" />
                <path
                    fill="#0F2535"
                    d="m261.63 413.43 9-3.71a3.51 3.51 0 0 0 1.9-4.56 3.5 3.5 0 0 0-4.56-1.89L259 407a3.5 3.5 0 0 0-1.9 4.55 3.51 3.51 0 0 0 4.53 1.88zm170.95-118.1c-4 17.7 4.39 32.47 8.27 33.9a14.42 14.42 0 0 0 8.75.47 14.08 14.08 0 0 0 10.4-10.1l8.52-33.6a14.62 14.62 0 0 0-9.38-16.5 18.16 18.16 0 0 0-4.71-.93c-3.25-.14-17.73 8.43-21.85 26.76z"
                />
                <path fill="#FDD0A2" d="M468.61 304.09c-2.75 10.728-13.645 17.224-24.39 14.54l-6.74-1.7c-10.728-2.75-17.224-13.645-14.54-24.39 2.745-10.727 13.638-17.223 24.38-14.54l6.74 1.7c10.729 2.75 17.227 13.643 14.55 24.39z" />
                <path fill="#FDD0A2" d="M424.26 289.09c1.63.41 2.55 2.34 2.06 4.31-.49 1.97-2.23 3.22-3.86 2.81-1.63-.41-2.54-2.34-2-4.31.54-1.97 2.17-3.22 3.8-2.81z" />
                <path fill="#0F2535" d="M455.73 322.1a5.38 5.38 0 0 1-3.43 6.76l-24.69 8.05a5.37 5.37 0 0 1-6.76-3.43 5.37 5.37 0 0 1 3.43-6.76l24.72-8.06a5.37 5.37 0 0 1 6.73 3.44z" />
                <path fill="#0F2535" d="M431.35 332.09a5.38 5.38 0 0 1-5.58 5.13l-27.68-1.16a5.38 5.38 0 0 1-5.13-5.58 5.38 5.38 0 0 1 5.58-5.13l27.68 1.16a5.38 5.38 0 0 1 5.13 5.58z" />
                <path fill="#FDD0A2" d="M385.64 326.31a1 1 0 0 0-1-1.08l-9.17-.37a1.06 1.06 0 0 0-1.09 1 1.06 1.06 0 0 0 1 1.09l9.17.36a1 1 0 0 0 1.09-1z" />
                <rect width="1.53" height="7.97" x="380.579" y="330.287" fill="#FDD0A2" rx=".56" transform="rotate(-87.74 381.344 334.272)" />
                <path
                    fill="#FDD0A2"
                    d="M384.46 329.14a1 1 0 0 0-1-1.08l-9.17-.37a1.06 1.06 0 0 0-1.09 1 1.06 1.06 0 0 0 1 1.09l9.17.36a1 1 0 0 0 1.09-1zm-10.334 2.439.004-.1a1 1 0 0 1 1.04-.96l9.262.371a1 1 0 0 1 .96 1.04l-.004.09a1 1 0 0 1-1.04.959l-9.262-.37a1 1 0 0 1-.96-1.04v.01z"
                />
                <path fill="#FDD0A2" d="M384.5 335.11c-.83-.13-2.49-.43-2.51-5.62-.02-5.19 3.3-4.56 3.3-4.56-.06-.36-.13-.71-.2-1.07 0 0-3.15-.17-2.2-3.2l6 2.75 3.39 3.29c-.09 2.38-.19 4.75-.28 7.13l-4 1.42s-2.67-.02-3.5-.14z" />
                <path fill="#FDD0A2" d="M398.54 330.5c.08-2-1.06-3.72-2.53-3.77l-4-.17c-1.47-.05-2.74 1.55-2.82 3.57-.08 2 1.06 3.72 2.53 3.78l4 .16c1.47.06 2.74-1.55 2.82-3.57z" />
                <path fill="#0F2535" d="M465.72 282a5.39 5.39 0 0 0 .19-7.58L448 255.6a5.38 5.38 0 0 0-7.58-.19 5.38 5.38 0 0 0-.19 7.58l17.9 18.82a5.38 5.38 0 0 0 7.59.19z" />
                <path fill="#0F2535" d="M449.55 258.38a5.38 5.38 0 0 0-6.26-4.27l-27.22 5.13a5.38 5.38 0 0 0-4.27 6.27 5.37 5.37 0 0 0 6.26 4.27l27.21-5.13a5.38 5.38 0 0 0 4.28-6.27z" />
                <path fill="#FDD0A2" d="M405.94 269.77a1 1 0 0 1-.83 1.22l-9 1.68a1 1 0 0 1-1.21-.83 1 1 0 0 1 .83-1.22l9-1.68a1 1 0 0 1 1.21.83z" />
                <rect width="7.97" height="1.53" x="399.568" y="261.232" fill="#FDD0A2" rx=".56" transform="rotate(-10.54 403.553 261.997)" />
                <path
                    fill="#FDD0A2"
                    d="M408.7 266.21a1 1 0 0 1-.84 1.21l-9 1.68a1 1 0 0 1-1.22-.83 1.06 1.06 0 0 1 .84-1.22l9-1.68a1.06 1.06 0 0 1 1.22.84zm-10.22-1.985 9.112-1.71a1 1 0 0 1 1.167.798l.018.098a1 1 0 0 1-.798 1.167l-9.111 1.71a1 1 0 0 1-1.167-.798l-.017-.088a1 1 0 0 1 .798-1.168l-.001-.01z"
                />
                <path fill="#FDD0A2" d="M403.55 261.23c-.81.24-2.41.78-1.68 5.92.73 5.14 3.92 4 3.92 4 0 .36 0 .73-.05 1.09 0 0-3.09.62-1.71 3.48l5.55-3.59 2.89-3.74c-.44-2.34-.87-4.68-1.31-7l-4.21-.82s-2.6.43-3.4.66z" />
                <path fill="#FDD0A2" d="M418.11 263.77c.37 2-.52 3.83-2 4.1l-4 .74c-1.44.27-2.93-1.13-3.3-3.12-.37-2 .51-3.83 2-4.1l4-.74c1.44-.27 2.92 1.13 3.3 3.12z" />
                <path
                    fill="#B08E68"
                    d="M439.57 299.23a14.37 14.37 0 0 0-13.47 10.92 18.44 18.44 0 0 0 9.89 6.4l9.73 2.46c9.92 2.48 19.981-3.522 22.51-13.43l.76-3c2.469-9.919-3.529-19.972-13.43-22.51l-9.73-2.46a19.89 19.89 0 0 0-3.69-.54 20.6 20.6 0 0 0-8.47 1.35 14.82 14.82 0 0 0-2.12 8.42c.45 7.8 7.34 12 8.02 12.39z"
                />
                <path fill="#B08E68" d="M480.28 307.63c-1.851 7.25-9.21 11.642-16.47 9.83-7.236-1.86-11.617-9.21-9.81-16.46 1.856-7.245 9.214-11.632 16.47-9.82 7.235 1.856 11.616 9.203 9.81 16.45z" />
                <path fill="#2F93E0" d="M345.06 38.05c14.66 11.17 36.08 14.6 40.53 8.3 1-1.37 1-3.05 1-4.75a16.94 16.94 0 0 0-7.48-14.6L345.6 4c-1.41-.76-10.19-5.34-16-1.38a8.56 8.56 0 0 0-2.3 2.37c-3.97 6.56 4.9 23.26 17.76 33.06z" />
                <path fill="#FDD0A2" d="M345.12 88.7s-8.17 2-11.09 1.71c.27-5.34 8.23-8.1 8.23-8.1l2.86 6.39z" />
                <rect width="41.03" height="48.13" x="331.014" y="7.543" fill="#FDD0A2" rx="18.94" transform="rotate(-145.53 351.53 31.608)" />
                <path fill="#FDD0A2" d="M335.13 49.56a3.42 3.42 0 1 1 1.19 4.21 3.11 3.11 0 0 1-1.19-4.21z" />
                <path fill="#261F18" d="m342.5 33.59 11 7.55a5.61 5.61 0 0 1 1.5 7.77 5.61 5.61 0 0 1-7.77 1.44l-11-7.55a5.6 5.6 0 0 1-1.5-7.8 5.61 5.61 0 0 1 7.77-1.41z" />
                <path
                    fill="#D6A756"
                    d="M331 25.47a7 7 0 0 0-.79 3.24 5.88 5.88 0 0 0 2.46 4.08 7.25 7.25 0 0 0 3.54 10A9.39 9.39 0 0 0 347 50.15a7.84 7.84 0 0 0 4.81 1.85 8 8 0 0 0 5.55-2.21 5.7 5.7 0 0 0 4.15.81 5.56 5.56 0 0 0 2.8-1.52 19.11 19.11 0 0 0 1.22-1.59l5.81-8.49c5.882-8.632 3.693-20.392-4.9-26.33l-2.6-1.79c-8.632-5.882-20.392-3.693-26.33 4.9l-5.8 8.46a7 7 0 0 0-.71 1.23z"
                />
                <path fill="#FDD0A2" d="M310.69 57.73a1 1 0 0 1 .85 1.17l-1.4 8.91a1 1 0 0 1-1.17.85 1 1 0 0 1-.86-1.17l1.4-8.91a1 1 0 0 1 1.18-.85z" />
                <rect width="7.83" height="1.5" x="298.822" y="56.666" fill="#FDD0A2" rx=".55" transform="rotate(-81.04 302.737 57.416)" />
                <path fill="#FDD0A2" d="M308.29 54a1 1 0 0 1 .85 1.17l-1.4 8.91a1 1 0 0 1-1.17.85 1 1 0 0 1-.86-1.17l1.4-8.91a1 1 0 0 1 1.18-.85z" />
                <rect width="11.07" height="2.05" x="299.281" y="57.456" fill="#FDD0A2" rx=".98" transform="rotate(-81.11 304.816 58.481)" />
                <path fill="#FDD0A2" d="M302 57.15c0 .82-.07 2.48 4.93 3.49 5 1.01 5-2.31 5-2.31l1 .4s-.43 3.07 2.66 2.72l-1.49-6.32-2.52-3.9-6.93-1.09-2.14 3.63s-.51 2.55-.51 3.38z" />
                <path
                    fill="#FDD0A2"
                    d="M309.11 44.49c2 .31 3.38 1.73 3.16 3.16l-.62 3.92c-.22 1.44-2 2.35-4 2-2-.3-3.38-1.73-3.16-3.16l.62-3.92c.25-1.39 2.04-2.31 4-2zm48.76 36.72a2.79 2.79 0 0 0-3.56-1.66L350.43 81a2.78 2.78 0 0 0-1.6 3.59 2.79 2.79 0 0 0 3.56 1.66l3.88-1.45a2.78 2.78 0 0 0 1.6-3.59z"
                />
                <path fill="#FDD0A2" d="m341.57 81.27.7 1s-3.8.8-1.47 6.28c.14.33.27.58.41.87 1.6.26 9.17-2.43 11.21-3.17l-2-5.22-5-2-7.57-.23c.32 3.68 3.72 2.47 3.72 2.47z" />
                <path fill="#2F93E0" d="M381.4 39.83a5.89 5.89 0 0 1 5.1 6.59l-2.95 23a5.9 5.9 0 0 1-6.6 5.1 5.9 5.9 0 0 1-5.09-6.6l2.94-23a5.91 5.91 0 0 1 6.6-5.09z" />
                <path
                    fill="#2F93E0"
                    d="M352.19 82.29a5.91 5.91 0 0 1 2.41-8l20.47-11a5.89 5.89 0 0 1 8 2.41 5.9 5.9 0 0 1-2.42 8l-20.46 11a5.89 5.89 0 0 1-8-2.41zm-15.3-76.41a5.88 5.88 0 0 0-8-2.38l-20.43 11a5.91 5.91 0 0 0-2.39 8 5.91 5.91 0 0 0 8 2.39l20.43-11a5.9 5.9 0 0 0 2.39-8.01z"
                />
                <path fill="#2F93E0" d="M307.77 48.4a5.9 5.9 0 0 0 6.58-5.12l2.87-23a5.9 5.9 0 0 0-5.12-6.58 5.9 5.9 0 0 0-6.58 5.12l-2.87 23a5.9 5.9 0 0 0 5.12 6.58z" />
                <path fill="#D6A756" d="M364.31 49.05s-19 14.61-42.8-17.33c5.55 2.3 7.1.38 7.1.38s-2.94-.83-4-2.64 1.49 2 6.67-4.55a38.61 38.61 0 0 1 18.92 6.58l14.11 17.56z" />
                <path
                    fill="#FDD0A2"
                    d="M372.94 40.42a2.12 2.12 0 0 1 .55 2.94l-3.06 4.45a2.12 2.12 0 0 1-2.95.55 2.11 2.11 0 0 1-.54-2.94L370 41a2.12 2.12 0 0 1 2.94-.58zM335.6 14.81a2.12 2.12 0 0 1 .55 3l-3 4.45a2.12 2.12 0 0 1-3 .55 2.13 2.13 0 0 1-.55-3l3.06-4.45a2.12 2.12 0 0 1 2.94-.55z"
                />
                <path
                    fill="#0F2535"
                    d="M64.94 124.07c12.32-17.86 3.78-40.2.59-42.94a16.22 16.22 0 0 0-3.65-2.31 16.35 16.35 0 0 0-4.77-1.12 16.08 16.08 0 0 0-14.36 7L20.73 117c-4.498 6.446-3.416 15.246 2.51 20.41a17.25 17.25 0 0 0 4.25 2.59c2.95 1.15 25.45 1.48 37.45-15.93z"
                />
                <rect width="39.43" height="48.68" x="28.389" y="87.46" fill="#FDD0A2" rx="18.67" transform="rotate(124.32 48.104 111.8)" />
                <path fill="#FDD0A2" d="M65.8 128.24a3.64 3.64 0 1 1 4.63-1.2 3.4 3.4 0 0 1-4.63 1.2z" />
                <path
                    fill="#0F2535"
                    d="M45.6 85.62c-.67-2.18.66-4.16 1.08-4.79.86-1.3 3.13-3.88 15.94-5.36 14.26-1.65 17.2.9 18 1.74a9 9 0 0 1 2.17 3.56 7.35 7.35 0 0 1-1 3.9c-1.68 2.71-5.11 3.68-13.38 4.5-4.23.42-9.8.8-16.43.81-3.19-.48-5.7-2.15-6.38-4.36z"
                />
                <path
                    fill="#4E3217"
                    d="M46.58 134.34c1.26-.34 9.83 3.36 19.07-10.57 8.89-13.41 2.79-21.24 2.72-21.82a20.39 20.39 0 0 0-4.26-3.9l-8.86-6c-9-6.14-21.12-4.17-26.95 4.37L26.53 99c-5.83 8.54-3.24 20.55 5.75 26.69l8.85 6a20.46 20.46 0 0 0 5.45 2.65z"
                />
                <path
                    fill="#FDD0A2"
                    d="M79.39 159.39a1.1 1.1 0 0 1 1.31-.9l10.11 1.9a1.21 1.21 0 0 1 1 1.34 1.1 1.1 0 0 1-1.32.9l-10.1-1.9a1.21 1.21 0 0 1-1-1.34zm-1.32 8.91.06-.43a.6.6 0 0 1 .71-.49l7.54 1.42a.66.66 0 0 1 .53.72l-.06.43a.59.59 0 0 1-.72.49L78.6 169a.65.65 0 0 1-.53-.7zm2.04-5.61a1.1 1.1 0 0 1 1.31-.9l10.1 1.9a1.22 1.22 0 0 1 1 1.34 1.11 1.11 0 0 1-1.32.9L81.09 164a1.21 1.21 0 0 1-.98-1.31zm-1.63 2.86v-.1a1 1 0 0 1 1.25-.86L90 166.51a1.16 1.16 0 0 1 .94 1.28v.1a1.05 1.05 0 0 1-1.25.86l-10.2-1.92a1.16 1.16 0 0 1-1.01-1.28z"
                />
                <path fill="#FDD0A2" d="M78.85 169c.94.08 2.81.17 3.89-5.34S80.05 158 80.05 158c.15-.36.29-.72.44-1.09 0 0 3.48.61 3.05-2.84l-7.14 1.41-4.4 2.66a960.202 960.202 0 0 1-1.13 7.65L75 168.3s2.92.7 3.85.7z" />
                <path fill="#FDD0A2" d="M64.43 160.62c.32-2.17 1.91-3.69 3.53-3.38l4.45.84c1.62.3 2.69 2.32 2.37 4.49-.32 2.16-1.91 3.69-3.54 3.38l-4.44-.84c-1.62-.3-2.69-2.32-2.37-4.49z" />
                <path
                    fill="#0F2535"
                    d="M25.82 127.23c-2.21.54-3.24 2.7-3.56 3.38-.67 1.41-1.73 4.68 3.56 16.44 5.89 13.1 9.58 14.31 10.74 14.61a9 9 0 0 0 4.17 0 7.3 7.3 0 0 0 2.83-2.88c1.47-2.83.54-6.27-3-13.79-1.81-3.85-4.33-8.83-7.72-14.53-2.05-2.46-4.78-3.77-7.02-3.23z"
                />
                <path
                    fill="#0F2535"
                    d="M31.87 154.73a8.52 8.52 0 0 0 1 4c1.69 3 5 4.09 18.87 6.6 10.67 1.93 14.14 2.26 16.65 0a7.54 7.54 0 0 0 2.13-3.42 8.17 8.17 0 0 0-1-4c-1.72-3-5-3.88-16.86-6.14C40 149.38 36.54 149 34 151.31a7.57 7.57 0 0 0-2.13 3.42z"
                />
                <path
                    fill="#FDD0A2"
                    d="M114.37 105.49a1.1 1.1 0 0 0 .48 1.52l9.14 4.7a1.19 1.19 0 0 0 1.59-.45 1.09 1.09 0 0 0-.48-1.51L116 105a1.21 1.21 0 0 0-1.63.49zm4.43-7.84-.21.38a.59.59 0 0 0 .26.82l6.81 3.51a.65.65 0 0 0 .86-.24l.22-.38a.59.59 0 0 0-.26-.82l-6.82-3.51a.65.65 0 0 0-.86.24zm-1.8 5.67a1.1 1.1 0 0 0 .48 1.52l9.14 4.71a1.22 1.22 0 0 0 1.6-.45 1.11 1.11 0 0 0-.48-1.52l-9.14-4.7a1.21 1.21 0 0 0-1.6.44zm.43-3.25-.05.09a1 1 0 0 0 .46 1.44l9.24 4.76a1.15 1.15 0 0 0 1.52-.43l.05-.09a1.06 1.06 0 0 0-.46-1.45L119 99.64a1.16 1.16 0 0 0-1.57.43z"
                />
                <path fill="#FDD0A2" d="M119.86 97.56c.78.51 2.32 1.59-.21 6.6S114 107 114 107c-.11.38-.22.76-.32 1.13 0 0 3.11 1.66.65 4.12l-4.76-5.5-1.78-4.75 3.8-6.73 4.81.56s2.68 1.17 3.46 1.73z" />
                <path fill="#FDD0A2" d="M103.32 95.33c-1.08 1.9-.76 4.08.71 4.84l4 2.07c1.47.75 3.55-.19 4.63-2.09 1.08-1.91.76-4.09-.71-4.84l-4-2.07c-1.47-.76-3.55.18-4.63 2.09z" />
                <path
                    fill="#0F2535"
                    d="M71 78.73a8.45 8.45 0 0 1 3.29-2.52c3.19-1.34 6.42-.19 19 6.38 9.6 5 12.54 6.9 13.14 10.22a7.52 7.52 0 0 1-.42 4 8.28 8.28 0 0 1-3.29 2.52c-3.19 1.31-6.3 0-17.07-5.52C74.11 88 71.13 86.1 70.53 82.73a7.82 7.82 0 0 1 .47-4z"
                />
                <path fill="#54AB54" d="M363.47 398.77c-15.14 9.41-25.61 27.6-21.45 33.78.9 1.35 2.43 1.93 3.94 2.59a16.4 16.4 0 0 0 15.82-1.77L394.11 411c1.16-1 8.33-7.38 6.73-13.95a8.43 8.43 0 0 0-1.35-2.9c-4.57-5.9-22.73-3.63-36.02 4.62z" />
                <rect width="39.69" height="46.56" x="347.204" y="383.573" fill="#FDD0A2" rx="18.32" transform="rotate(-34.69 367.049 406.853)" />
                <path fill="#FDD0A2" d="M356.48 385.83a3.31 3.31 0 1 1-4.21-.37 3 3 0 0 1 4.21.37z" />
                <path
                    fill="#D48B40"
                    d="M368.41 382.44c-5.22 2.87-17.24 10.7-16.37 21.54a7.87 7.87 0 0 0-4.87-2.08 7.62 7.62 0 0 0-3.78.78 19.14 19.14 0 0 0 3.39 11.65l6 8.61c6.093 8.755 18.116 10.94 26.9 4.89l2.65-1.83c8.755-6.097 10.944-18.119 4.9-26.91l-6-8.61a19.26 19.26 0 0 0-12.82-8.04z"
                />
                <path fill="#54AB54" d="M350 431.72c-1.65 1.64-5.9 3.08-7.27 1.69l-19.84-23c-1.37-1.38-1.14-3.86.51-5.5 1.66-1.64 4.14-1.85 5.51-.47l22.19 19.82c1.32 1.39.51 5.82-1.1 7.46z" />
                <path fill="#FDD0A2" d="M325.21 410.88a3.56 3.56 0 0 1-3.13-3.92l3.68-30.29a3.48 3.48 0 0 1 3.68-3.32c1.94.22 1.65 2.67 1.43 4.61l-1.74 29.79a3.56 3.56 0 0 1-3.92 3.13z" />
                <rect width="11.66" height="6.4" x="320.578" y="404.432" fill="#54AB54" rx="1.59" transform="rotate(-41.18 326.408 407.632)" />
                <path fill="#54AB54" d="M396.59 402.15c2.1-1 4.88-4.52 4-6.28l-15-26.41c-.84-1.76-3.25-2.37-5.35-1.36-2.11 1-3.14 3.27-2.29 5l11.22 27.55c.83 1.79 5.32 2.51 7.42 1.5z" />
                <path fill="#FDD0A2" d="M385.6 370.53a3.57 3.57 0 0 0-2.61-4.29l-29.71-7a3.49 3.49 0 0 0-4.38 2.32c-.46 1.89 1.94 2.46 3.83 2.92l28.58 8.62a3.56 3.56 0 0 0 4.29-2.57z" />
                <rect width="11.66" height="6.4" x="375.985" y="368.737" fill="#54AB54" rx="1.59" transform="rotate(150.78 381.815 371.937)" />
                <path
                    fill="#D48B40"
                    d="M382.29 426s22.46-14.68-4.32-43c-4.65-4.72-11.57-6.11-13.87-5.7-.72.11 3.5 2.73 2.89 6.05-.61 3.32 5.91 9.4 5.91 9.4l9.39 33.25zm-2.82 2s-21.65 15.85-38.76-19.2c-2.78-6-1.64-13-.44-15 .19-.35 1.66 5.05 4.69 8.88a18.43 18.43 0 0 0 6.66 4.8L379.47 428z"
                />
                <path fill="#FDD0A2" d="M331.52 370a.74.74 0 0 0 .79-.67l.53-6.37a.73.73 0 0 0-.66-.79.74.74 0 0 0-.79.67l-.53 6.37a.73.73 0 0 0 .66.79z" />
                <rect width="5.56" height="1.07" x="323.316" y="366.228" fill="#FDD0A2" rx=".39" transform="rotate(-85.21 326.096 366.763)" />
                <path
                    fill="#FDD0A2"
                    d="M329.59 369.13a.73.73 0 0 0 .78-.67l.54-6.37a.72.72 0 0 0-.66-.78.73.73 0 0 0-.79.66l-.54 6.37a.74.74 0 0 0 .67.79zm-2.06.57h.06a.69.69 0 0 0 .75-.63l.55-6.44a.71.71 0 0 0-.64-.75h-.06a.69.69 0 0 0-.75.63l-.54 6.49a.69.69 0 0 0 .63.7z"
                />
                <path fill="#FDD0A2" d="M325.43 369c.11-.57.37-1.72 4-1.57 3.63.15 3.07 2.44 3.07 2.44l.75-.11a1.61 1.61 0 0 1 2.3-1.43l-2.1 4.1-2.39 2.26-5-.42-.86-2.86s.12-1.87.23-2.41z" />
                <path
                    fill="#FDD0A2"
                    d="M328.2 378.89c1.41.12 2.63-.62 2.71-1.65l.24-2.8c.09-1-1-2-2.39-2.08-1.41-.12-2.63.62-2.71 1.65l-.24 2.8c-.09 1.03.99 1.96 2.39 2.08zm17.12-16.47a.73.73 0 0 1-.89.51l-6.17-1.67a.72.72 0 0 1-.51-.89.73.73 0 0 1 .89-.52l6.17 1.67a.73.73 0 0 1 .51.9z"
                />
                <rect width="1.07" height="5.56" x="343.581" y="353.439" fill="#FDD0A2" rx=".39" transform="rotate(-74.84 344.116 356.22)" />
                <path fill="#FDD0A2" d="M345.13 360.29a.73.73 0 0 1-.89.51l-6.18-1.67a.73.73 0 0 1-.172-1.331.72.72 0 0 1 .552-.069l6.18 1.67a.73.73 0 0 1 .51.89z" />
                <rect width="1.45" height="7.85" x="341.861" y="353.652" fill="#FDD0A2" rx=".69" transform="rotate(-74.91 342.586 357.577)" />
                <path fill="#FDD0A2" d="M346.4 356.33c-.58-.09-1.74-.24-2.84 3.21-1.1 3.45 1.24 3.72 1.24 3.72-.12.23-.24.45-.37.67a1.61 1.61 0 0 0-2.12 1.67l4.57-.57 2.95-1.48 1.29-4.8-2.38-1.75s-1.74-.58-2.34-.67z" />
                <path fill="#FDD0A2" d="M354.78 362.32c-.37 1.36-1.48 2.25-2.47 2l-2.72-.74c-1-.27-1.5-1.6-1.13-3 .36-1.36 1.47-2.25 2.47-2l2.71.73c.99.32 1.5 1.69 1.14 3.01z" />
            </g>
        </svg>
    );
}
