import { useOctopusRepository } from "@octopusdeploy/octopus-react-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LicenseStatusResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { ChevronDownIcon, OctopusIcon, resetStyles, useDropdownButton, borderRadius, borderWidth, color, space, textStyles, useQuery, css, themeTokens } from "@octopusdeploy/portal-design-system";
import { useOctopusSessionPermissions, useOctopusSessionUser } from "@octopusdeploy/session";
import React from "react";
import { SpaceCallToActionPanel } from "./SpaceCallToActionPanel";
import { SpaceLogo } from "./SpaceLogo";
import { SpaceSearchPanel } from "./SpaceSearchPanel/SpaceSearchPanel";

export interface SpaceSwitcherProps {
    currentSpace: SpaceResource | undefined;
    onNewSpaceRequested: () => void;
    onUnlockMoreSpacesRequested: () => void;
    getSpaceHref: (space: SpaceResource) => string;
}

export function SpaceSwitcher({ currentSpace, onNewSpaceRequested, onUnlockMoreSpacesRequested, getSpaceHref }: SpaceSwitcherProps) {
    const { isOpen, toggleDropdown, setButtonRef, buttonAriaAttributes, setDropdownRef, dropdownAttributes } = useDropdownButton({ trapFocus: true, dropdownAriaRole: "dialog" });

    const { dropdownId, dropdownPositionStyles, dropdownAriaAttributes } = dropdownAttributes;

    const repository = useOctopusRepository();
    const currentUser = useOctopusSessionUser();
    const currentPermissions = useOctopusSessionPermissions();

    const { refetch: refetchSpaces, data: spaces } = useQuery(() => repository.Users.getSpaces(currentUser));
    const { data: licenseStatus } = useQuery(() => repository.Licenses.getCurrentStatus());

    React.useEffect(() => {
        if (isOpen) {
            refetchSpaces();
        }
    }, [isOpen, refetchSpaces]);

    const loadedCurrentSpace = currentSpace ? spaces?.find((space) => space.Id === currentSpace.Id) : undefined;
    const latestCurrentSpace = loadedCurrentSpace ?? currentSpace;

    const hasMultipleSpaces = spaces !== undefined && spaces.length > 1;
    const hasSpaceCreatePermission = currentPermissions.scopeToSpaceAndSystem(currentSpace?.Id ?? null).isAuthorized({
        permission: Permission.SpaceCreate,
    });
    const isWithinLicenseLimit = licenseStatus ? spacesIsWithinLicenceLimit(licenseStatus) : false;

    let dropdownContent = undefined;
    if (hasMultipleSpaces) {
        dropdownContent = <SpaceSearchPanel spaces={spaces} canCreateNewSpace={hasSpaceCreatePermission && isWithinLicenseLimit} onNewSpaceRequested={onNewSpaceRequested} getSpaceHref={getSpaceHref} />;
    } else if (hasSpaceCreatePermission) {
        const callToActionLabel = isWithinLicenseLimit ? "Create new space" : "Unlock more spaces";
        const onCallToActionRequested = isWithinLicenseLimit ? onNewSpaceRequested : onUnlockMoreSpacesRequested;

        dropdownContent = <SpaceCallToActionPanel callToActionButtonLabel={callToActionLabel} onCallToActionRequested={onCallToActionRequested} />;
    }

    return (
        <>
            <button className={spaceButtonStyles} ref={setButtonRef} onClick={dropdownContent ? toggleDropdown : undefined} {...(dropdownContent ? buttonAriaAttributes : undefined)} aria-label="Space switcher">
                <div className={spaceLogoContainerStyles}>{latestCurrentSpace ? <SpaceLogo space={latestCurrentSpace} /> : <OctopusIcon />}</div>
                {latestCurrentSpace && <span className={spaceNameStyles}>{latestCurrentSpace.Name}</span>}
                {dropdownContent && <ChevronDownIcon />}
            </button>
            {isOpen && dropdownContent && (
                <div ref={setDropdownRef} id={dropdownId} className={spaceSwitcherDropdownStyles} style={dropdownPositionStyles} {...dropdownAriaAttributes} aria-label="Space switcher dialog">
                    {dropdownContent}
                </div>
            )}
        </>
    );
}

function spacesIsWithinLicenceLimit(licenseStatus: LicenseStatusResource): boolean {
    const spacesLimitStatus = licenseStatus.Limits.find((l) => l.Name === "Spaces");
    return spacesLimitStatus ? spacesLimitStatus.CurrentUsage < spacesLimitStatus.EffectiveLimit : false;
}

const spaceButtonStyles = css({
    ...resetStyles.button,
    ...textStyles.button.primary.base,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",

    gap: space[5],

    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color, border-color, outline, color, fill",
    transitionDuration: "0.2s",

    borderRadius: borderRadius.medium,

    paddingLeft: space["2px"],
    paddingTop: space["2px"],
    paddingBottom: space["2px"],
    paddingRight: space[5],

    maxWidth: "15rem",

    height: "3rem",

    color: color.scale.white,
    background: color.scale.blue[500],
    outline: "transparent solid 3px",
    border: `${space["1px"]} solid ${color.scale.blue[300]}`,

    // TODO: Proper tokens for colors
    ":hover": {
        background: color.scale.blue[600],
        borderColor: color.scale.blue[200],
    },
    ":focus-visible": {
        background: color.scale.blue[500],
        outlineColor: color.scale.blue[400],
        borderColor: color.scale.blue[700],
    },
    ":active": {
        background: color.scale.blue[700],
        borderColor: color.scale.white,
    },
});

const spaceLogoContainerStyles = css({
    alignSelf: "stretch",
    aspectRatio: "1",
    borderRadius: borderRadius.small,
    background: color.scale.white,
    padding: space[2],
});

const spaceNameStyles = css({
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
});

const spaceSwitcherDropdownStyles = css({
    width: "29rem",
    border: `${borderWidth[1]} solid ${color.scale.slate[200]}`,
    background: color.scale.white,
    borderRadius: borderRadius.medium,
    boxShadow: themeTokens.shadow.medium,
    overflow: "hidden",
});
