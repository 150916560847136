export const shadow = {
    "extra-small": "var(--shadowExtra-small)",
    "small": "var(--shadowSmall)",
    "medium": "var(--shadowMedium)",
    "large": "var(--shadowLarge)"
} as const;

export const color = {
    "surface": {
        "card": "var(--colorSurfaceCard)",
        "code": "var(--colorSurfaceCode)",
        "table": {
            "header": "var(--colorSurfaceTableHeader)",
            "body": "var(--colorSurfaceTableBody)"
        }
    },
    "text": {
        "interface": {
            "primary": "var(--colorTextInterfacePrimary)",
            "secondary": "var(--colorTextInterfaceSecondary)"
        },
        "button": {
            "solid": {
                "primary": {
                    "base": "var(--colorTextButtonSolidPrimaryBase)",
                    "hover": "var(--colorTextButtonSolidPrimaryHover)",
                    "focus": "var(--colorTextButtonSolidPrimaryFocus)",
                    "active": "var(--colorTextButtonSolidPrimaryActive)",
                    "disabled": "var(--colorTextButtonSolidPrimaryDisabled)"
                },
                "secondary": {
                    "base": "var(--colorTextButtonSolidSecondaryBase)",
                    "hover": "var(--colorTextButtonSolidSecondaryHover)",
                    "focus": "var(--colorTextButtonSolidSecondaryFocus)",
                    "active": "var(--colorTextButtonSolidSecondaryActive)",
                    "disabled": "var(--colorTextButtonSolidSecondaryDisabled)"
                }
            },
            "ghost": {
                "primary": {
                    "base": "var(--colorTextButtonGhostPrimaryBase)",
                    "hover": "var(--colorTextButtonGhostPrimaryHover)",
                    "focus": "var(--colorTextButtonGhostPrimaryFocus)",
                    "active": "var(--colorTextButtonGhostPrimaryActive)",
                    "disabled": "var(--colorTextButtonGhostPrimaryDisabled)"
                },
                "secondary": {
                    "base": "var(--colorTextButtonGhostSecondaryBase)",
                    "hover": "var(--colorTextButtonGhostSecondaryHover)",
                    "focus": "var(--colorTextButtonGhostSecondaryFocus)",
                    "active": "var(--colorTextButtonGhostSecondaryActive)",
                    "disabled": "var(--colorTextButtonGhostSecondaryDisabled)"
                }
            }
        },
        "input": "var(--colorTextInput)",
        "inputPlaceholder": "var(--colorTextInputPlaceholder)",
        "navigationBar": {
            "link": {
                "base": "var(--colorTextNavigationBarLinkBase)",
                "hover": "var(--colorTextNavigationBarLinkHover)",
                "focus": "var(--colorTextNavigationBarLinkFocus)",
                "active": "var(--colorTextNavigationBarLinkActive)",
                "selected": "var(--colorTextNavigationBarLinkSelected)"
            }
        }
    },
    "background": {
        "button": {
            "solid": {
                "primary": {
                    "base": "var(--colorBackgroundButtonSolidPrimaryBase)",
                    "hover": "var(--colorBackgroundButtonSolidPrimaryHover)",
                    "focus": "var(--colorBackgroundButtonSolidPrimaryFocus)",
                    "active": "var(--colorBackgroundButtonSolidPrimaryActive)",
                    "disabled": "var(--colorBackgroundButtonSolidPrimaryDisabled)"
                },
                "secondary": {
                    "base": "var(--colorBackgroundButtonSolidSecondaryBase)",
                    "hover": "var(--colorBackgroundButtonSolidSecondaryHover)",
                    "focus": "var(--colorBackgroundButtonSolidSecondaryFocus)",
                    "active": "var(--colorBackgroundButtonSolidSecondaryActive)",
                    "disabled": "var(--colorBackgroundButtonSolidSecondaryDisabled)"
                }
            },
            "ghost": {
                "primary": {
                    "base": "var(--colorBackgroundButtonGhostPrimaryBase)",
                    "hover": "var(--colorBackgroundButtonGhostPrimaryHover)",
                    "focus": "var(--colorBackgroundButtonGhostPrimaryFocus)",
                    "active": "var(--colorBackgroundButtonGhostPrimaryActive)",
                    "disabled": "var(--colorBackgroundButtonGhostPrimaryDisabled)"
                },
                "secondary": {
                    "base": "var(--colorBackgroundButtonGhostSecondaryBase)",
                    "hover": "var(--colorBackgroundButtonGhostSecondaryHover)",
                    "focus": "var(--colorBackgroundButtonGhostSecondaryFocus)",
                    "active": "var(--colorBackgroundButtonGhostSecondaryActive)",
                    "disabled": "var(--colorBackgroundButtonGhostSecondaryDisabled)"
                }
            }
        },
        "navigationBar": {
            "link": {
                "base": "var(--colorBackgroundNavigationBarLinkBase)",
                "hover": "var(--colorBackgroundNavigationBarLinkHover)",
                "focus": "var(--colorBackgroundNavigationBarLinkFocus)",
                "active": "var(--colorBackgroundNavigationBarLinkActive)",
                "selected": "var(--colorBackgroundNavigationBarLinkSelected)"
            }
        }
    },
    "outline": {
        "button": {
            "solid": {
                "primary": {
                    "focus": "var(--colorOutlineButtonSolidPrimaryFocus)"
                },
                "secondary": {
                    "focus": "var(--colorOutlineButtonSolidSecondaryFocus)"
                }
            },
            "ghost": {
                "primary": {
                    "focus": "var(--colorOutlineButtonGhostPrimaryFocus)"
                },
                "secondary": {
                    "focus": "var(--colorOutlineButtonGhostSecondaryFocus)"
                }
            }
        },
        "navigationBar": {
            "link": {
                "focus": "var(--colorOutlineNavigationBarLinkFocus)"
            }
        },
        "input": {
            "focus": "var(--colorOutlineInputFocus)"
        }
    },
    "border": {
        "input": {
            "base": "var(--colorBorderInputBase)",
            "hover": "var(--colorBorderInputHover)"
        },
        "table": "var(--colorBorderTable)"
    }
} as const;