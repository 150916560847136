import type { LoggingSink, LogEvent } from "../types";

interface AsyncLoggingSink extends LoggingSink {
    cancelAnyPendingLogEvents(): void;
}

export function createAsyncLoggingSink(loggingSink: LoggingSink): AsyncLoggingSink {
    let timeouts: ReturnType<typeof setTimeout>[] = [];

    return {
        receiveLogEvents(logEvents: LogEvent[]) {
            const timeout = setTimeout(() => {
                loggingSink.receiveLogEvents(logEvents);
                timeouts = timeouts.filter((t) => t !== timeout);
            });
            timeouts = [...timeouts, timeout];
        },
        cancelAnyPendingLogEvents() {
            timeouts.forEach(clearTimeout);
        },
    };
}
