import type { Client } from "../client";
import type { LibraryVariableSetResource, NewLibraryVariableSetResource, VariableSetContentType } from "../resources/libraryVariableSetResource";
import type { LibraryVariableSetUsageResource } from "../resources/libraryVariableSetUsageResource";
import type { AllArgs, ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";

type LibraryVariableAllArgs = {
    contentType?: VariableSetContentType;
} & AllArgs;

type LibraryVariableListArgs = {
    contentType?: VariableSetContentType;
} & ListArgs;

export class LibraryVariableRepository extends BasicRepository<LibraryVariableSetResource, NewLibraryVariableSetResource, LibraryVariableListArgs, LibraryVariableAllArgs> {
    constructor(client: Client) {
        super("LibraryVariables", client);
    }

    getUsages(libraryVariableSet: LibraryVariableSetResource): Promise<LibraryVariableSetUsageResource> {
        return this.client.get(libraryVariableSet.Links["Usages"]);
    }
}
