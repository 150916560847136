import { autoUpdate, computePosition, offset } from "@floating-ui/react-dom";
import type { AriaAttributes, CSSProperties } from "react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useFocusTrap } from "../../hooks/useFocusTrap";
import { isHTMLElement } from "../../utils/isHTMLElement";

interface UseDropdownButtonOptions {
    trapFocus: boolean;
    dropdownAriaRole: DropdownAriaRole;
}
export function useDropdownButton({ trapFocus, dropdownAriaRole }: UseDropdownButtonOptions): UseDropdownButtonResult {
    const id = React.useRef(`dropdown-${uuidv4()}`);
    const [isOpen, setIsOpen] = React.useState(false);
    const [buttonElement, setButtonElement] = React.useState<HTMLButtonElement | null>(null);
    const [dropdownElement, setDropdownElement] = React.useState<HTMLDivElement | null>(null);
    const [dropdownPosition, setDropdownPosition] = React.useState({ x: 0, y: 0 });
    const setFocusTrapElement = useFocusTrap();

    React.useLayoutEffect(() => {
        // autoUpdate is expensive so we shouldn't run it unless both elements are mounted and visible
        if (!isOpen || buttonElement === null || dropdownElement === null) return;

        return autoUpdate(buttonElement, dropdownElement, async () => {
            const position = await computePosition(buttonElement, dropdownElement, {
                placement: "bottom-start",
                middleware: [offset(8)],
            });

            setDropdownPosition(position);
        });
    }, [buttonElement, dropdownElement, isOpen]);

    React.useEffect(() => {
        if (!isOpen || buttonElement === null || dropdownElement === null) return;

        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsOpen(false);
                buttonElement?.focus();
                event.preventDefault();
            }
        };

        const onFocusInOrPointerDown = (event: FocusEvent | PointerEvent) => {
            if (isHTMLElement(event.target) && !buttonElement.contains(event.target) && !dropdownElement.contains(event.target)) {
                setIsOpen(false);
            }
        };

        window.addEventListener("keydown", onKeyDown);
        window.addEventListener("focusin", onFocusInOrPointerDown);
        window.addEventListener("pointerdown", onFocusInOrPointerDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
            window.removeEventListener("focusin", onFocusInOrPointerDown);
            window.removeEventListener("pointerdown", onFocusInOrPointerDown);
        };
    }, [isOpen, buttonElement, dropdownElement]);

    const buttonAriaAttributes: ButtonAriaAttributes = {
        "aria-expanded": isOpen,
        "aria-haspopup": dropdownAriaRole,
        "aria-controls": isOpen ? id.current : undefined,
    };

    const dropdownAttributes: DropdownAttributes = {
        dropdownId: id.current,
        dropdownPositionStyles: {
            position: "absolute",
            top: dropdownPosition.y,
            left: dropdownPosition.x,
        },
        dropdownAriaAttributes: {
            role: dropdownAriaRole,
        },
    };

    return {
        isOpen,
        toggleDropdown: () => setIsOpen((isOpen) => !isOpen),
        setButtonRef: setButtonElement,
        buttonAriaAttributes,
        setDropdownRef: (dropdown: HTMLDivElement) => {
            setDropdownElement(dropdown);
            if (trapFocus) {
                setFocusTrapElement(dropdown);
            }
        },
        dropdownAttributes,
    };
}

interface UseDropdownButtonResult {
    isOpen: boolean;
    toggleDropdown: () => void;
    setButtonRef: (button: HTMLButtonElement) => void;
    buttonAriaAttributes: ButtonAriaAttributes;
    setDropdownRef: (dropdown: HTMLDivElement) => void;
    dropdownAttributes: DropdownAttributes;
}

interface ButtonAriaAttributes {
    "aria-expanded": boolean;
    "aria-haspopup": DropdownAriaRole;
    "aria-controls": string | undefined;
}

interface DropdownAttributes {
    dropdownId: string;
    dropdownPositionStyles: {
        position: CSSProperties["position"];
        left: CSSProperties["left"];
        top: CSSProperties["top"];
    };
    dropdownAriaAttributes: {
        role: DropdownAriaRole;
    };
}

type DropdownAriaRole = Exclude<AriaAttributes["aria-haspopup"], boolean | undefined>;
