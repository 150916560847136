// TODO: this should be generated and imported from tokens

export const size = {
    button: {
        // TODO: Should these be rem?
        large: 44,
        medium: 38,
        small: 32,
        "extra-small": 25,
    },
    input: "2.5rem",
} as const;

export const font = {
    size: {
        // TODO: same as body?
        button: {
            large: "1.25rem",
            medium: "1rem",
            small: "0.875rem",
            "extra-small": "0.75rem",
        },
    },
} as const;
