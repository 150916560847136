import type { VariablePromptDisplaySettings } from "./variableResource";

export interface FormCheckboxControl extends FormControl {
    Text: string;
}

export interface FormParagraphControl extends FormControl {
    Text: string;
    ResolveLinks: boolean;
}

export interface FormButtonControl {
    Text: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Value: any;
    RequiresConfirmation: boolean;
}

export interface FormSubmitButtonGroupControl extends FormControl {
    Buttons: FormButtonControl[];
}

export interface FormTextAreaControl extends FormControl {
    Label: string;
}

export interface FormVariableValueControl extends FormControl {
    Name: string;
    Label: string;
    Description: string;
    Required: boolean;
    DisplaySettings: VariablePromptDisplaySettings;
}

export enum FormControlType {
    Checkbox = "Checkbox",
    Paragraph = "Paragraph",
    Button = "Button",
    SubmitButtonGroup = "SubmitButtonGroup",
    TextArea = "TextArea",
    VariableValue = "VariableValue",
}

export interface FormControl {
    Type: FormControlType;
}

export interface FormElement {
    Name: string;
    Control: FormControl;
    IsValueRequired: boolean;
}

export interface Form {
    Values: { [name: string]: string };
    Elements: FormElement[];
}
