/* eslint-disable @typescript-eslint/no-explicit-any */

import type { RouteArgs } from "../resolver";
import type { ResourceWithId, ResourceCollection, Resource } from "../resources";
import type { ListArgs, AllArgs, ResourcesById } from "./basicRepository";
import { BasicRepository } from "./basicRepository";

export interface SpaceQueryParameters {
    includeSystem: boolean;
    spaces: string[];
}
// includeSystem is set to true by default, can be overridden by args
export class MixedScopeBaseRepository<TExistingResource extends ResourceWithId, TNewResource extends Resource, TListArgs extends ListArgs = ListArgs, TAllArgs extends AllArgs = AllArgs, TGetArgs extends RouteArgs = {}> extends BasicRepository<
    TExistingResource,
    TNewResource,
    TListArgs
> {
    list(args?: TListArgs): Promise<ResourceCollection<TExistingResource>> {
        const combinedArgs = super.extend(this.spacePartitionParameters(), args);
        return super.list(combinedArgs);
    }

    get(id: string, args?: TGetArgs): Promise<TExistingResource> {
        const allArgs = this.extend(args || {}, { id });
        const argsWithSpace = this.extend(this.spacePartitionParameters(), allArgs);
        return super.get(id, argsWithSpace);
    }

    all(args?: TAllArgs): Promise<TExistingResource[]> {
        const combinedArgs = super.extend(this.spacePartitionParameters(), args);
        return super.all(combinedArgs);
    }

    allById(args?: any): Promise<ResourcesById<TExistingResource>> {
        const combinedArgs = super.extend(this.spacePartitionParameters(), args);
        return super.allById(combinedArgs);
    }

    protected spacePartitionParameters() {
        return convertToSpacePartitionParameters(this.client.spaceId, true);
    }
}

export function convertToSpacePartitionParameters(spaceId: string | null | "all", includeSystem: boolean) {
    const spaces = spaceId ? [spaceId] : [];
    return { includeSystem, spaces };
}
