import type { CSSObject } from "@emotion/css";

const buttonResetStyles: CSSObject = {
    all: "unset",
    cursor: "pointer",
};

const anchorResetStyles: CSSObject = {
    all: "unset",
    cursor: "pointer",
};

const ulResetStyles: CSSObject = {
    all: "unset",
    listStyle: "none",
};

const headingResetStyles: CSSObject = {
    all: "unset",
};

export const resetStyles = {
    button: buttonResetStyles,
    anchor: anchorResetStyles,
    ul: ulResetStyles,
    heading: headingResetStyles,
};
