import type { LoggingSink, LogEvent } from "../types";

export type BufferingSinkEnabledPredicate = () => boolean | "indeterminate";

export interface BufferingSink extends LoggingSink {
    flush: () => Promise<unknown>;
}

export function createBufferingSink(sink: LoggingSink, isEnabled: BufferingSinkEnabledPredicate = () => true, interval: number = 1000): BufferingSink {
    const logEventQueue: LogEvent[] = [];

    async function flushQueue() {
        const logEventsToSend = logEventQueue.splice(0);
        if (logEventsToSend.length > 0) {
            sink.receiveLogEvents(logEventsToSend);
        }
        await sink.flush?.();
    }

    setInterval(() => {
        if (isEnabled() === true && logEventQueue.length > 0) {
            // A future optimisation here would be to not send all events in one single request, but limit the batch size
            flushQueue();
        }
    }, interval);

    return {
        receiveLogEvents(logEvents: LogEvent[]) {
            if (isEnabled() !== false) {
                // If the enabled state is indeterminate, continue to accrue events just in case
                logEventQueue.push(...logEvents);
            }
        },
        flush: flushQueue,
    };
}
