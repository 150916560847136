import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { css, borderRadius } from "@octopusdeploy/portal-design-system";
import { useOctopusPathResolver } from "@octopusdeploy/routing";
import React from "react";

interface SpaceLogoProps {
    space: SpaceResource;
}

export const SpaceLogo = ({ space }: SpaceLogoProps) => {
    const resolvePath = useOctopusPathResolver();
    return <img className={spaceLogoStyles} src={resolvePath(space.Links.Logo)} />;
};

const spaceLogoStyles = css({
    borderRadius: borderRadius.small,
    objectFit: "scale-down",
    width: "100%",
    height: "100%",
});
