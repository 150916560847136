import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-component-tokens";
import React from "react";

export interface ButtonIconProps {
    position: ButtonIconPosition;
    icon: React.ReactNode;
}

export function ButtonIcon({ position, icon }: ButtonIconProps) {
    const styles = [buttonIconStyles, position === "left" ? buttonIconLeftStyles : buttonIconRightStyles];

    return <span className={cx(styles)}>{icon}</span>;
}

export type ButtonIconPosition = "left" | "right";

const buttonIconStyles = css({
    display: "inline-flex",
});

const buttonIconLeftStyles = css({
    marginRight: space[3],
});
const buttonIconRightStyles = css({
    marginLeft: space[3],
});
