/* eslint-disable @typescript-eslint/no-explicit-any */

import type { Client } from "../client";
import type { GitRefResource } from "../resources";
import type { VariableSetResource } from "../resources/variableSetResource";
import type { GitRef } from "../resources/versionControlledResource";
import { BasicRepository } from "./basicRepository";

export class VariableRepository extends BasicRepository<VariableSetResource, VariableSetResource> {
    constructor(client: Client) {
        super("Variables", client);
    }

    getForGitRef(gitRef: GitRefResource): Promise<VariableSetResource> {
        return this.client.get(gitRef.Links.Variables);
    }

    getNamesForDeploymentProcess(projectId: string, projectEnvironmentsFilter?: any, gitRef?: GitRef): Promise<string[]> {
        return this.client.get(this.client.getLink("VariableNames"), {
            project: projectId,
            gitRef: gitRef,
            projectEnvironmentsFilter: projectEnvironmentsFilter ? projectEnvironmentsFilter.join(",") : projectEnvironmentsFilter,
        });
    }

    getNamesForRunbookProcess(projectId: string, runbookId: string, projectEnvironmentsFilter?: any): Promise<string[]> {
        return this.client.get(this.client.getLink("VariableNames"), {
            project: projectId,
            runbook: runbookId,
            projectEnvironmentsFilter: projectEnvironmentsFilter ? projectEnvironmentsFilter.join(",") : projectEnvironmentsFilter,
        });
    }

    getSpecialVariableNames(): Promise<string[]> {
        return this.client.get(this.client.getLink("VariableNames"), {});
    }

    preview(projectId: string, runbookId: string | undefined, actionId: string, environmentId: string, machineId: string, channelId: string, tenantId: string, gitRef?: GitRef): Promise<VariableSetResource> {
        return this.client.get(this.client.getLink("VariablePreview"), {
            project: projectId,
            runbook: runbookId,
            environment: environmentId,
            channel: channelId,
            tenant: tenantId,
            action: actionId,
            machine: machineId,
            gitRef: gitRef,
        });
    }
}
