/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

// We use our own AJAX function so that we"re not too coupled to jQuery
import { KnownErrorCodes } from "./KnownErrorCodes";
import type { ClientSession } from "./client";
import { OctopusError } from "./resources/octopusError";
//TODO: remove direct repository import from client instance as it is a circular dependency

interface AjaxOptions {
    session: ClientSession;
    url: string;
    method?: string;
    success: (data: PromiseLike<string> | string) => void;
    error: (error: OctopusError) => void;
    raw?: boolean;
    requestBody?: string;
    nonStale?: boolean;
    tryGetServerInformation: () => ServerInformation;
    getAntiForgeryTokenCallback: () => string;
    onRequestCallback: (details: AjaxRequestDetails) => void;
    onResponseCallback: (details: AjaxResponseDetails) => void;
    onErrorResponseCallback: (details: AjaxErrorResponseDetails) => void;
}

export interface ServerInformation {
    version: string;
}

export interface AjaxRequestDetails {
    correlationId: number;
    request: XMLHttpRequest;
    url: string;
    method: string;
}

export interface AjaxResponseDetails {
    correlationId: number;
    request: XMLHttpRequest;
    url: string;
    method: string;
    statusCode: number;
}

export interface AjaxErrorResponseDetails {
    correlationId: number;
    request: XMLHttpRequest;
    url: string;
    method: string;
    statusCode: number;
    errorMessage: string;
    errors: string[];
}

let correlationId: number = 0;

export class Ajax {
    options: AjaxOptions;

    constructor(options: AjaxOptions) {
        this.options = options;
        correlationId++;
    }

    execute() {
        const request = createXMLHTTPObject();
        if (!request) {
            return;
        }

        request.open(getMethod(this.options.method!).method, this.options.url, true);
        request.withCredentials = true;

        this.setHeaders(request);

        const cachedResult = this.options.session.cache.setHeaderAndGetValue(request, this.options);

        request.onreadystatechange = async () => {
            if (request.readyState !== request.DONE) {
                return;
            } else if (request.status < 100 || request.status >= 400) {
                await this.handleError(request);
            } else {
                this.handleSuccess(request, cachedResult!);
            }
        };

        if (request.readyState === request.DONE) {
            return;
        }

        this.sendRequest(request);
    }

    private sendRequest(request: XMLHttpRequest) {
        if (this.options.onRequestCallback) {
            const details = {
                correlationId,
                request,
                method: this.options.method!,
                url: this.options.url!,
            };
            this.options.onRequestCallback(details);
        }

        const body = this.options.requestBody;
        if (typeof body === "undefined") {
            request.send();
        } else if (typeof body === "string" || isFormData(body)) {
            request.send(body);
        } else {
            const bodyJson = JSON.stringify(body);
            request.send(bodyJson);
        }
    }

    private setHeaders(request: XMLHttpRequest) {
        const { method, methodOverride } = getMethod(this.options.method!);

        const serverInfo = this.options.tryGetServerInformation();
        const version = serverInfo ? serverInfo.version : undefined;
        request.setRequestHeader("X-Octopus-User-Agent", "OctopusClient-js/" + version);

        request.setRequestHeader("Accept", "application/json");

        if (this.options.requestBody && !isFormData(this.options.requestBody)) {
            request.setRequestHeader("Content-type", "application/json");
        }

        if (methodOverride) {
            request.setRequestHeader("X-HTTP-Method-Override", methodOverride);
        }

        // Set X-Octopus-Csrf-Token header if there is an antiforgery cookie available and it"s a mutating request
        if (method === "POST" || method === "PUT" || method === "DELETE") {
            const antiforgeryToken = this.options.getAntiForgeryTokenCallback();
            if (antiforgeryToken) {
                request.setRequestHeader("X-Octopus-Csrf-Token", antiforgeryToken);
            }
        }
    }

    private handleSuccess = (request: XMLHttpRequest, cachedResult: string) => {
        let responseBody = cachedResult;
        if (!responseBody || !this.options.session.cache.canUseCachedValue(request)) {
            responseBody = request.responseText;
            this.options.session.cache.updateCache(request, this.options);
        }

        if (this.options.onResponseCallback) {
            const details = {
                correlationId,
                request,
                method: this.options.method!,
                url: this.options.url,
                statusCode: request.status,
            };
            this.options.onResponseCallback(details);
        }

        this.options.success(deserialize(responseBody, this.options.raw!));
    };

    private handleError = async (request: XMLHttpRequest) => {
        const err = generateOctopusError(request);

        if (this.options.onErrorResponseCallback) {
            const details = {
                correlationId,
                request,
                method: this.options.method!,
                url: this.options.url,
                statusCode: request.status,
                errorMessage: err.ErrorMessage,
                errors: err.Errors!,
            };
            this.options.onErrorResponseCallback(details);
        }

        this.options.error(err);
    };
}

const deserialize = (responseText: string, raw: boolean, forceJson = false) => {
    if (raw && !forceJson) {
        return responseText;
    }

    if (responseText && responseText.length) {
        return JSON.parse(responseText);
    }

    return null;
};

const getMethod = (method: string): { method: string; methodOverride: string | undefined | null } => {
    let methodOverride: string | null = null;
    if (method === "PUT") {
        methodOverride = "PUT";
        method = "POST";
    }
    if (method === "DELETE") {
        methodOverride = "DELETE";
        method = "POST";
    }
    return { method, methodOverride };
};

const isFormData = (item: {}): boolean => {
    return item instanceof FormData;
};

const generateOctopusError = (request: XMLHttpRequest) => {
    let error = new OctopusError(request.status);

    try {
        if (request.status === request.UNSENT) {
            error = OctopusError.create(KnownErrorCodes.NetworkError, null);
            error.ErrorMessage = "There was a problem with your request.";
            error.Errors = ["Unable to establish a connection to the Octopus Deploy server. " + "The server may be offline. Please check your network connection."];
        } else {
            error = OctopusError.create(request.status, deserialize(request.responseText, false, true));

            if (!error.ErrorMessage) {
                error.ErrorMessage = "There was a problem with your request.";
                error.Errors = ["The Octopus Deploy server returned a status of: " + request.status];
                error.FullException = request.response;
            }
        }
    } catch (err) {
        error.ErrorMessage = "There was a problem with your request.";
        error.Errors = ["Unhandled error when communicating with the Octopus Deploy server. " + "The server returned a status of: " + request.status];
        error.FullException = err.toString();
    }
    return error;
};

const XMLHttpFactories = [
    () => {
        return new XMLHttpRequest();
    },
    () => {
        return new ActiveXObject("Msxml2.XMLHTTP") as XMLHttpRequest;
    },
    () => {
        return new ActiveXObject("Msxml3.XMLHTTP") as XMLHttpRequest;
    },
    () => {
        return new ActiveXObject("MSXML2.XMLHTTP.3.0") as XMLHttpRequest;
    },
    () => {
        return new ActiveXObject("Microsoft.XMLHTTP") as XMLHttpRequest;
    },
];

const createXMLHTTPObject = () => {
    let xmlhttp: XMLHttpRequest | null = null;
    for (let i = 0; i < XMLHttpFactories.length; i++) {
        try {
            xmlhttp = XMLHttpFactories[i]();
        } catch (e) {
            continue;
        }
        break;
    }
    return xmlhttp;
};
