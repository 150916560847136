import { css } from "@emotion/css";
import { color } from "@octopusdeploy/design-system-component-tokens";
import React from "react";
import { NavigationBarDropdownButton } from "./NavigationBarDropdownButton";
import { NavigationBarLink } from "./NavigationBarLink";

export interface NavigationBarItemProps {
    item: NavigationBarItemData;
}

export interface NavigationBarItemData {
    label: string;
    href: string;
    isActive?: boolean;
    dropdown?: {
        accessibleName: string;
    };
}

export function NavigationBarItem({ item }: NavigationBarItemProps) {
    const link = <NavigationBarLink label={item.label} href={item.href} isActive={item.isActive} />;

    if (item.dropdown) {
        return (
            <div className={navigationBarGroupStyles}>
                {link}
                <NavigationBarDropdownButton accessibleName={item.dropdown.accessibleName} />
            </div>
        );
    }

    return link;
}

const navigationBarGroupStyles = css({
    display: "flex",

    "> *:not(:last-child)": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

        borderRight: `1px solid ${color.scale.navy[600]}`,
    },
    "> *:not(:first-child)": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
});
