export const color = {
    "scale": {
        "white": "#FFFFFF",
        "black": "#000000",
        "alpha": {
            "0": "rgba(0, 0, 0, 0)",
            "10": "rgba(0,0,0,0.1)",
            "12": "rgba(0,0,0,0.12)",
            "02": "rgba(0,0,0,0.02)",
            "04": "rgba(0,0,0,0.04)",
            "06": "rgba(0,0,0,0.06)"
        },
        "blue": {
            "100": "#F2F8FD",
            "200": "#CDE4F7",
            "300": "#87BFEC",
            "400": "#449BE1",
            "500": "#1A77CA",
            "600": "#155EA0",
            "700": "#0F4778",
            "800": "#093051",
            "900": "#041A2D"
        },
        "green": {
            "100": "#EEFAF5",
            "200": "#B8E7D3",
            "300": "#5ECD9E",
            "400": "#00AB62",
            "500": "#00874D",
            "600": "#006A3D",
            "700": "#00502E",
            "800": "#00361F",
            "900": "#001E11"
        },
        "purple": {
            "100": "#F8F5FD",
            "200": "#E7DEF8",
            "300": "#C5AEEE",
            "400": "#A683E5",
            "500": "#895ED3",
            "600": "#7043B7",
            "700": "#572B97",
            "800": "#3B1D66",
            "900": "#21103A"
        },
        "orange": {
            "100": "#FFF5ED",
            "200": "#FFDABF",
            "300": "#FFA461",
            "400": "#EA7325",
            "500": "#C45317",
            "600": "#A13C14",
            "700": "#7E2812",
            "800": "#59170D",
            "900": "#340B07"
        },
        "yellow": {
            "100": "#FFF7D9",
            "200": "#FFDF62",
            "300": "#E5B203",
            "400": "#B98F02",
            "500": "#927002",
            "600": "#745801",
            "700": "#584201",
            "800": "#3B2C00",
            "900": "#201800"
        },
        "red": {
            "100": "#FFF3F3",
            "200": "#FFD8D8",
            "300": "#FF9F9F",
            "400": "#FF5D5D",
            "500": "#D63D3D",
            "600": "#AA3030",
            "700": "#802424",
            "800": "#571818",
            "900": "#310E0E"
        },
        "navy": {
            "100": "#f4f6f8",
            "200": "#dae2e9",
            "300": "#a9bbcb",
            "400": "#7C98B4",
            "500": "#557999",
            "600": "#3e607d",
            "700": "#2e475d",
            "800": "#1f303f",
            "900": "#111a23"
        },
        "slate": {
            "100": "#f5f6f8",
            "200": "#dee1e6",
            "300": "#b2b9c5",
            "400": "#8a96a7",
            "500": "#68778d",
            "600": "#515d70",
            "700": "#3d4653",
            "800": "#282f38",
            "900": "#16191f"
        },
        "grey": {
            "100": "#f7f7f7",
            "200": "#e1e1e1",
            "300": "#b9b9b9",
            "400": "#959595",
            "500": "#757575",
            "600": "#5c5c5c",
            "700": "#454545",
            "800": "#2e2e2e",
            "900": "#191919"
        }
    }
} as const;

export const space = {
    "1": "0.25rem",
    "2": "0.375rem",
    "3": "0.5rem",
    "4": "0.75rem",
    "5": "1rem",
    "6": "1.5rem",
    "7": "2rem",
    "8": "3rem",
    "9": "4rem",
    "10": "5rem",
    "2px": "2px",
    "1px": "1px"
} as const;

export const fontWeight = {
    "400": 400,
    "500": 500,
    "700": 700
} as const;

export const lineHeight = {
    "xSmall": "calc(1rem*1.25)",
    "small": "calc(1rem*1.5)",
    "medium": "calc(1rem*1.75)",
    "large": "calc(1rem*2)",
    "xLarge": "calc(1rem*2.5)",
    "2xLarge": "calc(1rem*2.75)"
} as const;

export const fontFamily = {
    "roboto": "Roboto",
    "roboto-mono": "Roboto Mono"
} as const;

export const fontSize = {
    "xSmall": "calc(1rem*0.75)",
    "small": "calc(1rem*0.8125)",
    "medium": "calc(1rem* 0.875)",
    "base": "1rem",
    "large": "calc(1rem*1.25)",
    "xLarge": "calc(1rem*1.5)",
    "2xLarge": "calc(1rem*2)"
} as const;

export const textDecoration = {
    "none": "none",
    "underline": "underline"
} as const;

export const textCase = {
    "none": "none",
    "uppercase": "uppercase",
    "lowercase": "lowercase"
} as const;

export const borderRadius = {
    "none": "0",
    "small": "2px",
    "medium": "4px",
    "large": "8px",
    "circle": "9999px"
} as const;

export const borderWidth = {
    "1": "1px",
    "2": "2px",
    "3": "3px",
    "4": "4px",
    "none": "0"
} as const;

export const letterSpacing = {
    "none": "0",
    "tight": "-0.00625rem",
    "wide": "0.00625rem",
    "wider": "0.0125rem"
} as const;

export const textScales = {
    "heading": {
        "large": {
            "normal": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*2.75)",
                    "fontSize": "calc(1rem*2)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*2.75)",
                    "fontSize": "calc(1rem*2)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*2.75)",
                    "fontSize": "calc(1rem*2)",
                    "letterSpacing": "0"
                }
            },
            "tight": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*2.5)",
                    "fontSize": "calc(1rem*2)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*2.5)",
                    "fontSize": "calc(1rem*2)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*2.5)",
                    "fontSize": "calc(1rem*2)",
                    "letterSpacing": "0"
                }
            }
        },
        "medium": {
            "normal": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*2)",
                    "fontSize": "calc(1rem*1.5)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*2)",
                    "fontSize": "calc(1rem*1.5)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*2)",
                    "fontSize": "calc(1rem*1.5)",
                    "letterSpacing": "0"
                }
            },
            "tight": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.75)",
                    "fontSize": "calc(1rem*1.5)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*1.75)",
                    "fontSize": "calc(1rem*1.5)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.75)",
                    "fontSize": "calc(1rem*1.5)",
                    "letterSpacing": "0"
                }
            }
        },
        "small": {
            "normal": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.75)",
                    "fontSize": "calc(1rem*1.25)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*1.75)",
                    "fontSize": "calc(1rem*1.25)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.75)",
                    "fontSize": "calc(1rem*1.25)",
                    "letterSpacing": "0"
                }
            },
            "tight": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "calc(1rem*1.25)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "calc(1rem*1.25)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "calc(1rem*1.25)",
                    "letterSpacing": "0"
                }
            }
        }
    },
    "body": {
        "base": {
            "normal": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "1rem",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "1rem",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "1rem",
                    "letterSpacing": "0"
                }
            },
            "tight": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "1rem",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "1rem",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "1rem",
                    "letterSpacing": "0"
                }
            }
        },
        "medium": {
            "normal": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "calc(1rem* 0.875)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "calc(1rem* 0.875)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "calc(1rem* 0.875)",
                    "letterSpacing": "0"
                }
            },
            "tight": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem* 0.875)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem* 0.875)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem* 0.875)",
                    "letterSpacing": "0"
                }
            }
        },
        "small": {
            "normal": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem*0.8125)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem*0.8125)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem*0.8125)",
                    "letterSpacing": "0"
                }
            }
        },
        "xSmall": {
            "normal": {
                "400": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem*0.75)",
                    "letterSpacing": "0"
                },
                "500": {
                    "fontFamily": "Roboto",
                    "fontWeight": 500,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem*0.75)",
                    "letterSpacing": "0"
                },
                "700": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem*0.75)",
                    "letterSpacing": "0"
                }
            }
        }
    }
} as const;

export const textStyles = {
    "button": {
        "subtle": {
            "base": {
                "fontFamily": "Roboto",
                "fontWeight": 400,
                "lineHeight": "calc(1rem*1.5)",
                "fontSize": "1rem",
                "letterSpacing": "0"
            },
            "medium": {
                "fontFamily": "Roboto",
                "fontWeight": 400,
                "lineHeight": "calc(1rem*1.5)",
                "fontSize": "calc(1rem* 0.875)",
                "letterSpacing": "0"
            }
        },
        "primary": {
            "base": {
                "fontFamily": "Roboto",
                "fontWeight": 700,
                "lineHeight": "calc(1rem*1.5)",
                "fontSize": "1rem",
                "letterSpacing": "0"
            },
            "medium": {
                "fontFamily": "Roboto",
                "fontWeight": 700,
                "lineHeight": "calc(1rem*1.5)",
                "fontSize": "calc(1rem* 0.875)",
                "letterSpacing": "0"
            },
            "small": {
                "fontFamily": "Roboto",
                "fontWeight": 700,
                "lineHeight": "calc(1rem*1.25)",
                "fontSize": "calc(1rem*0.75)",
                "letterSpacing": "0"
            }
        }
    },
    "chip": {
        "base": {
            "fontFamily": "Roboto",
            "fontWeight": 400,
            "lineHeight": "calc(1rem*1.5)",
            "fontSize": "1rem",
            "letterSpacing": "0"
        },
        "medium": {
            "fontFamily": "Roboto",
            "fontWeight": 400,
            "lineHeight": "calc(1rem*1.5)",
            "fontSize": "calc(1rem* 0.875)",
            "letterSpacing": "0"
        }
    },
    "code": {
        "default": {
            "fontFamily": "Roboto Mono",
            "fontWeight": 400,
            "lineHeight": "calc(1rem*1.5)",
            "fontSize": "1rem",
            "letterSpacing": "0"
        }
    },
    "nav": {
        "tab": {
            "label": {
                "fontFamily": "Roboto",
                "fontWeight": 400,
                "lineHeight": "calc(1rem*1.5)",
                "fontSize": "1rem",
                "letterSpacing": "0"
            }
        },
        "secondary": {
            "label": {
                "fontFamily": "Roboto",
                "fontWeight": 400,
                "lineHeight": "calc(1rem*1.25)",
                "fontSize": "calc(1rem*0.8125)",
                "letterSpacing": "0"
            },
            "heading": {
                "fontFamily": "Roboto",
                "fontWeight": 500,
                "lineHeight": "calc(1rem*1.25)",
                "fontSize": "calc(1rem*0.75)",
                "letterSpacing": "0"
            }
        },
        "primary": {
            "label": {
                "fontFamily": "Roboto",
                "fontWeight": 500,
                "lineHeight": "calc(1rem*1.25)",
                "fontSize": "calc(1rem*0.75)",
                "letterSpacing": "0"
            }
        }
    },
    "body": {
        "default": {
            "base": {
                "fontFamily": "Roboto",
                "fontWeight": 400,
                "lineHeight": "calc(1rem*1.5)",
                "fontSize": "1rem",
                "letterSpacing": "0"
            },
            "medium": {
                "fontFamily": "Roboto",
                "fontWeight": 400,
                "lineHeight": "calc(1rem*1.5)",
                "fontSize": "calc(1rem* 0.875)",
                "letterSpacing": "0"
            },
            "small": {
                "fontFamily": "Roboto",
                "fontWeight": 400,
                "lineHeight": "calc(1rem*1.25)",
                "fontSize": "calc(1rem*0.8125)",
                "letterSpacing": "0"
            },
            "xSmall": {
                "fontFamily": "Roboto",
                "fontWeight": 400,
                "lineHeight": "calc(1rem*1.25)",
                "fontSize": "calc(1rem*0.75)",
                "letterSpacing": "0"
            }
        },
        "bold": {
            "base": {
                "fontFamily": "Roboto",
                "fontWeight": 700,
                "lineHeight": "calc(1rem*1.5)",
                "fontSize": "1rem",
                "letterSpacing": "0"
            },
            "medium": {
                "fontFamily": "Roboto",
                "fontWeight": 700,
                "lineHeight": "calc(1rem*1.5)",
                "fontSize": "calc(1rem* 0.875)",
                "letterSpacing": "0"
            },
            "small": {
                "fontFamily": "Roboto",
                "fontWeight": 700,
                "lineHeight": "calc(1rem*1.25)",
                "fontSize": "calc(1rem*0.8125)",
                "letterSpacing": "0"
            },
            "xSmall": {
                "fontFamily": "Roboto",
                "fontWeight": 700,
                "lineHeight": "calc(1rem*1.25)",
                "fontSize": "calc(1rem*0.75)",
                "letterSpacing": "0"
            }
        }
    },
    "heading": {
        "medium": {
            "fontFamily": "Roboto",
            "fontWeight": 700,
            "lineHeight": "calc(1rem*2)",
            "fontSize": "calc(1rem*1.5)",
            "letterSpacing": "0"
        },
        "small": {
            "fontFamily": "Roboto",
            "fontWeight": 700,
            "lineHeight": "calc(1rem*1.75)",
            "fontSize": "calc(1rem*1.25)",
            "letterSpacing": "0"
        }
    },
    "legal": {
        "default": {
            "fontFamily": "Roboto",
            "fontWeight": 500,
            "lineHeight": "calc(1rem*1.25)",
            "fontSize": "calc(1rem*0.8125)",
            "letterSpacing": "0"
        }
    },
    "breadcrumb": {
        "default": {
            "fontFamily": "Roboto",
            "fontWeight": 400,
            "lineHeight": "calc(1rem*1.25)",
            "fontSize": "calc(1rem*0.75)",
            "letterSpacing": "0"
        }
    },
    "table": {
        "cell": {
            "default": {
                "base": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "1rem",
                    "letterSpacing": "0"
                },
                "medium": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "calc(1rem* 0.875)",
                    "letterSpacing": "0"
                },
                "xSmall": {
                    "fontFamily": "Roboto",
                    "fontWeight": 400,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem*0.75)",
                    "letterSpacing": "0"
                }
            },
            "bold": {
                "base": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "1rem",
                    "letterSpacing": "0"
                },
                "medium": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.5)",
                    "fontSize": "calc(1rem* 0.875)",
                    "letterSpacing": "0"
                },
                "xSmall": {
                    "fontFamily": "Roboto",
                    "fontWeight": 700,
                    "lineHeight": "calc(1rem*1.25)",
                    "fontSize": "calc(1rem*0.75)",
                    "letterSpacing": "0"
                }
            }
        }
    }
} as const;