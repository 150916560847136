import type { SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { WildflySensitiveStringProperties, WildflyStringProperties } from "./WildflyProperties";

const StringProperties = {
    "WildFly.Deploy.Name": "",
    "WildFly.Deploy.Port": "",
    "WildFly.Deploy.Controller": "",
    "WildFly.Deploy.User": "",
    "WildFly.Deploy.Protocol": "",
    "WildFly.Deploy.ServerType": "",
    "WildFly.Deploy.Enabled": "",
    "WildFly.Deploy.DisabledServerGroup": "",
    "WildFly.Deploy.EnabledServerGroup": "",
};

const SensitiveProperties = {
    "WildFly.Deploy.Password": "",
};

export const AllWildflyStateProperties = {
    ...StringProperties,
    ...SensitiveProperties,
    ...WildflyStringProperties,
    ...WildflySensitiveStringProperties,
};

export type WildflyStateProperties = {
    [P in keyof typeof StringProperties]: string;
} & {
    [P in keyof typeof WildflyStringProperties]: string;
} & {
    [P in keyof typeof SensitiveProperties]: SensitiveValue;
} & {
    [P in keyof typeof WildflySensitiveStringProperties]: SensitiveValue;
};
