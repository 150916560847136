import React from "react";
import { iconStyles } from "./iconStyles";

export function ExternalLinkIcon() {
    return (
        <svg className={iconStyles} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
            <path d="M14.3188 3C13.9063 3 13.5688 2.66438 13.5688 2.25C13.5688 1.83562 13.9063 1.5 14.3188 1.5H21.8188C22.2313 1.5 22.5688 1.83562 22.5688 2.25V9.75C22.5688 10.1625 22.2313 10.5 21.8188 10.5C21.4063 10.5 21.0688 10.1625 21.0688 9.75V4.06078L10.3485 14.7797C10.0579 15.075 9.57979 15.075 9.28916 14.7797C8.99385 14.4891 8.99385 14.0109 9.28916 13.7203L20.0095 3H14.3188ZM1.56885 5.25C1.56885 4.00734 2.57619 3 3.81885 3H9.81885C10.2313 3 10.5688 3.33562 10.5688 3.75C10.5688 4.16438 10.2313 4.5 9.81885 4.5H3.81885C3.40447 4.5 3.06885 4.8375 3.06885 5.25V20.25C3.06885 20.6625 3.40447 21 3.81885 21H18.8188C19.2313 21 19.5688 20.6625 19.5688 20.25V14.25C19.5688 13.8375 19.9063 13.5 20.3188 13.5C20.7313 13.5 21.0688 13.8375 21.0688 14.25V20.25C21.0688 21.4922 20.061 22.5 18.8188 22.5H3.81885C2.57619 22.5 1.56885 21.4922 1.56885 20.25V5.25Z" />
        </svg>
    );
}
