/*
To use Tiny types, copy the follow pattern

export type SomeStringTinyType = { readonly __someStringTinyType: unique symbol };
export const asSomeString = (value: string) => convertToTinyType<SomeStringTinyType, string>(value);
export const valueOfSomeString = (someString: SomeStringTinyType) => convertFromTinyType<SomeStringTinyType, string>(someString);

We use a type definition like { readonly __someStringTinyType: unique symbol } for the tiny type itself.
The __someStringTinyType property is a "brand" which ensures the shape of this object won't match any other type.

This type definition is also a lie - at runtime, the value of any variable with this type will always be the underlying primitive value (eg a string or number)
This allows us to serialize and deserialize the type without requiring an extra layer that maps to a more complex runtime value
This also means that there is no runtime cost of using these tiny types.

We expose mapping function (`asSomeString` and `valueOfSomeString` in the example above) to allow you to convert between the tiny type and the underlying value
The implementation of these functions simply casts the type, and does nothing at runtime.

Do not use these convertToTinyType/convertFromTinyType functions directly in your code, because they do not have any constraints on TTinyType.
Instead, always follow the pattern that first wraps them safely in functions like asSomeString and valueOfSomeString, as described above.
 */

export function convertToTinyType<TTinyType, TValue>(value: TValue): TTinyType {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value as unknown as TTinyType;
}

export function convertFromTinyType<TTinyType, TValue>(tinyType: TTinyType): TValue {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return tinyType as unknown as TValue;
}
