/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Client } from "../client";
import type { PermissionDescriptions } from "../resources/permissionDescriptions";

export class PermissionDescriptionRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    all(): Promise<PermissionDescriptions> {
        return this.client.get(this.client.getLink("PermissionDescriptions"), null!);
    }
}
