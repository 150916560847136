import type { Client } from "../client";
import type { ParseTaskLogsResponse } from "../resources/parseTaskLogsResponse";

export class DiagnosticsRepository {
    private client: Client;

    constructor(client: Client) {
        this.client = client;
    }

    async parseTaskLogs(files: File[]) {
        const fd = new FormData();
        files.map((f) => fd.append(f.name, f));
        return await this.client.post<ParseTaskLogsResponse>("~/bff/diagnostics/parse-task-logs", fd);
    }
}
