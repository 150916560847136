import type { Repository } from "@octopusdeploy/octopus-server-client";
import React from "react";

interface OctopusClientContextValue {
    repository: Repository;
}

const OctopusClientContext = React.createContext<OctopusClientContextValue | undefined>(undefined);

export interface OctopusClientProviderProps extends OctopusClientContextValue {
    children: React.ReactNode;
}

export function OctopusClientProvider({ repository, children }: OctopusClientProviderProps) {
    const contextValue = React.useMemo(
        () => ({
            repository,
        }),
        [repository]
    );
    return <OctopusClientContext.Provider value={contextValue}>{children}</OctopusClientContext.Provider>;
}

export function useOctopusRepository() {
    const contextValue = React.useContext(OctopusClientContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusClientProvider");
    }
    return contextValue.repository;
}
