import React from "react";

/**
 * This hook is a temporary solution for data loading in the portal components.
 * This is likely to be removed and replaced with a different solution in the future.
 * As such, you shouldn't move this out of portal-design-system, or copy paste this anywhere.
 * @param queryFn A async function that executes the query and returns the data.
 */
export function useQuery<T>(queryFn: () => Promise<T>): QueryResult<T> {
    const [queryState, dispatch] = React.useReducer<QueryStateReducer<T>>(queryStateReducer, { isLoading: true, error: undefined, data: undefined });

    const latestQueryFn = React.useRef(queryFn);
    latestQueryFn.current = queryFn;

    React.useEffect(() => {
        if (queryState.isLoading) {
            const getData = async () => {
                try {
                    const data = await latestQueryFn.current();
                    dispatch({ type: "success", data });
                } catch (e) {
                    if (e instanceof Error) {
                        dispatch({ type: "error", error: e });
                    } else {
                        dispatch({ type: "error", error: new Error(`Unknown error: ${e}`) });
                    }
                }
            };

            getData();
        }
    }, [queryState]);

    const refetch = React.useCallback(() => {
        dispatch({ type: "loading" });
    }, []);

    const queryResult = React.useMemo(
        () => ({
            ...queryState,
            refetch,
        }),
        [queryState, refetch]
    );

    return queryResult;
}

type QueryAction<T> = { type: "loading" } | { type: "success"; data: T } | { type: "error"; error: Error };
type QueryStateReducer<T> = React.Reducer<QueryState<T>, QueryAction<T>>;

const queryStateReducer = <T>(state: QueryState<T>, action: QueryAction<T>): QueryState<T> => {
    switch (action.type) {
        case "loading": {
            return {
                isLoading: true,
                error: undefined,
                data: state.data,
            };
        }
        case "success": {
            return {
                isLoading: false,
                error: undefined,
                data: action.data,
            };
        }
        case "error": {
            return {
                isLoading: false,
                error: action.error,
                data: state.data,
            };
        }
    }
};

export interface QueryLoadingState<T> {
    isLoading: true;
    error: undefined;
    data: T | undefined;
}

export interface QuerySuccessState<T> {
    isLoading: false;
    error: undefined;
    data: T;
}

export interface QueryErrorState<T> {
    isLoading: false;
    error: Error;
    data: T | undefined;
}

export type QueryState<T> = QueryLoadingState<T> | QuerySuccessState<T> | QueryErrorState<T>;

export type QueryResult<T> = QueryState<T> & {
    refetch: () => void;
};
