export function assertType<AssertionResult extends "true">() {
    // This function should only be invoked in type test files. These files never need to be run directly, but are only compiled.
}

// From set theory, 2 sets are equal if they are both subsets of each other
// We also don't want to distribute over conditional types in this mapped type
// e.g. We want IsEqual<string | boolean, string> to be `false`, since you can't assign a `boolean` to `string`
// If we typed this as `T extends U ? U extends T ? "true" : "false" : "false""` then typescript would distribute over `string | object`
// so then `IsEqual<string | boolean, string> = IsEqual<string, string> | IsEqual<boolean, string> = "true" | "false";
// This is clearly not the result we want, but if we opt out of distributing over conditional types, then we would get the result we want
// We can do this using a 1-tuple. Source: https://github.com/microsoft/TypeScript/issues/29368#issuecomment-453529532
export type IsEqual<T1, T2> = [T1] extends [T2] ? ([T2] extends [T1] ? "true" : "false") : "false";
export type IsNotEqual<T1, T2> = IsEqual<T1, T2> extends "true" ? "false" : "true";
export type IsAssignable<TypeBeingAssigned, TypeToAssignTo> = TypeBeingAssigned extends TypeToAssignTo ? "true" : "false";
export type IsNotAssignable<TypeBeingAssigned, TypeToAssignTo> = IsAssignable<TypeBeingAssigned, TypeToAssignTo> extends "true" ? "false" : "true";
