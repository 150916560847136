import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { css, space, Button, PlusIcon, SearchInput } from "@octopusdeploy/portal-design-system";
import type { KeyboardEvent } from "react";
import React, { useState } from "react";
import { NoSearchResults } from "./NoSearchResults";
import type { SpaceListElement } from "./SpaceList";
import { SpaceList } from "./SpaceList";

export interface SpaceSearchPanelProps {
    spaces: SpaceResource[];
    canCreateNewSpace: boolean;
    onNewSpaceRequested: () => void;
    getSpaceHref: (space: SpaceResource) => string;
}

export function SpaceSearchPanel({ spaces, canCreateNewSpace, onNewSpaceRequested, getSpaceHref }: SpaceSearchPanelProps) {
    const spaceListRef = React.useRef<SpaceListElement>(null);
    const [searchInput, setSearchInput] = useState("");

    const filteredSpaces = React.useMemo(() => filterSpaces(spaces, searchInput), [spaces, searchInput]);

    const noResults = searchInput.length > 0 && filteredSpaces.length === 0;

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === "ArrowDown") {
            spaceListRef.current?.focus();
            event.preventDefault();
        }
    };

    return (
        <div className={spaceSearchPanelStyles}>
            <div className={headerStyles} onKeyDown={onKeyDown}>
                <SearchInput placeholder="Quick search all spaces" value={searchInput} onChange={setSearchInput} autoFocus={true} accessibleName="Search spaces" />
                {canCreateNewSpace && <Button label="New space" color="primary" variant="ghost" leftIcon={<PlusIcon />} size="medium" onClick={onNewSpaceRequested} />}
            </div>
            {noResults ? <NoSearchResults searchInput={searchInput} onClearFilter={() => setSearchInput("")} /> : <SpaceList spaces={filteredSpaces} getSpaceHref={getSpaceHref} ref={spaceListRef} />}
        </div>
    );
}

function filterSpaces(spaces: SpaceResource[], filter: string) {
    const query = filter.toLowerCase().trim();
    if (!query) return spaces;

    return spaces.filter(({ Name, Description }: SpaceResource) => Name.toLowerCase().includes(query) || Description?.toLowerCase().includes(query));
}

const spaceSearchPanelStyles = css({
    display: "flex",
    flexDirection: "column",
    maxHeight: "33rem",
});

const headerStyles = css({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoFlow: "column",
    gridAutoColumns: "auto",
    columnGap: space[3],
    paddingTop: space[6],
    paddingLeft: space[6],
    paddingRight: space[6],
    paddingBottom: space[3],
});
