/* eslint-disable @typescript-eslint/no-explicit-any */

import type { Client } from "../client";
import type { DeploymentTargetResource, ResourceCollection } from "../resources";
import type { MachinePolicyResource, WorkerMachineResource } from "../resources/index";
import { BasicRepository } from "./basicRepository";

export class MachinePolicyRepository extends BasicRepository<MachinePolicyResource, any> {
    constructor(client: Client) {
        super("MachinePolicies", client);
    }
    getTemplate(): Promise<MachinePolicyResource> {
        return this.client.get(this.client.getLink("MachinePolicyTemplate"));
    }
    getMachines(machinePolicy: MachinePolicyResource): Promise<ResourceCollection<DeploymentTargetResource>> {
        return this.client.get(machinePolicy.Links["Machines"]);
    }
    getWorkers(machinePolicy: MachinePolicyResource): Promise<ResourceCollection<WorkerMachineResource>> {
        return this.client.get(machinePolicy.Links["Workers"]);
    }
}
