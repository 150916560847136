import type { SensitiveValue } from "@octopusdeploy/octopus-server-client";

export const WildflyStringProperties = {
    "WildFly.Deploy.Port": "",
    "WildFly.Deploy.Controller": "",
    "WildFly.Deploy.User": "",
    "WildFly.Deploy.Protocol": "",
    "WildFly.Deploy.ServerType": "",
    "WildFly.Deploy.DisabledServerGroup": "",
    "WildFly.Deploy.EnabledServerGroup": "",
};

export const WildflySensitiveStringProperties = {
    "WildFly.Deploy.Password": "",
};

export type WildflyProperties = {
    [P in keyof typeof WildflyStringProperties]: string;
} & {
    [P in keyof typeof WildflySensitiveStringProperties]: SensitiveValue | string;
};
