import { css, color, space, textStyles, Button, ExternalLinkIcon } from "@octopusdeploy/portal-design-system";
import { OctoLink } from "@octopusdeploy/utilities";
import React from "react";
import { OctopusRoundTable } from "./OctopusRoundTable";

interface SpaceCallToActionPanelProps {
    callToActionButtonLabel: string;
    onCallToActionRequested: () => void;
}

export function SpaceCallToActionPanel({ callToActionButtonLabel, onCallToActionRequested }: SpaceCallToActionPanelProps) {
    return (
        <div className={spaceCallToActionPanelStyles}>
            <h3 className={headingStyles}>Create spaces for your teams</h3>
            <p className={copyStyles}>Spaces allow teams to group their projects, infastructure, tenants and libary assets</p>
            <div className={buttonContainerStyles}>
                <Button label={callToActionButtonLabel} color="primary" variant="solid" size="medium" onClick={onCallToActionRequested} />
            </div>
            <OctopusRoundTable />
            <span className={learnMoreStyles}>
                {"Learn more about "}
                <a className={linkStyles} href={OctoLink.Create("spaces")}>
                    Spaces <ExternalLinkIcon />
                </a>
            </span>
        </div>
    );
}

const spaceCallToActionPanelStyles = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "35rem",
    paddingRight: space[8],
    paddingLeft: space[8],
    paddingTop: space[7],
    paddingBottom: space[8],
});

const headingStyles = css({
    ...textStyles.heading.small,
    marginBottom: space[4],
});

const copyStyles = css({
    ...textStyles.body.default.base,
    color: color.scale.grey["400"],
    marginTop: 0,
    marginBottom: space[6],
    textAlign: "center",
    maxWidth: "20rem",
});

const buttonContainerStyles = css({
    marginBottom: space[7],
});

const learnMoreStyles = css({
    ...textStyles.body.default.base,
    marginTop: space[7],
});

const linkStyles = css({
    color: color.scale.blue[400],
    textDecoration: "none",
    display: "inline-flex",
    alignItems: "center",
    gap: 5,

    ":hover": {
        textDecoration: "underline",
    },
});
