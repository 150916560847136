import { css, color, space, textStyles, Button, resetStyles } from "@octopusdeploy/portal-design-system";
import React from "react";

interface NoSearchResultsProps {
    searchInput: string;
    onClearFilter: () => void;
}

export function NoSearchResults({ searchInput, onClearFilter }: NoSearchResultsProps) {
    return (
        <div className={noSearchResultsStyles}>
            <h3 className={noSearchResultsHeadingStyles}>No results found</h3>
            <div className={noSearchResultsCopyStyles}>
                {`Couldn't find any spaces matching`}
                <span className={searchQueryContainerStyles}>
                    {`"`}
                    <span className={searchQueryStyles}>{searchInput}</span>
                    {`"`}
                </span>
            </div>
            <Button label="Clear filter" color="primary" variant="ghost" size="medium" onClick={onClearFilter} />
        </div>
    );
}

const noSearchResultsStyles = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: space[4],
    paddingTop: space[7],
    paddingBottom: "3.5rem",
    paddingLeft: "3.5rem",
    paddingRight: "3.5rem",
});

const noSearchResultsHeadingStyles = css({
    ...resetStyles.heading,
    ...textStyles.heading.small,
});

const noSearchResultsCopyStyles = css({
    ...textStyles.body.default.base,
    color: color.scale.grey["500"],
    textAlign: "center",
});

const searchQueryContainerStyles = css({
    display: "flex",
    justifyContent: "center",
});

const searchQueryStyles = css({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "20rem",
});
