import React from "react";
import { ChevronDownIcon } from "../Icon";
import { navigationBarItemStyles } from "./navigationBarStyles";

export interface NavigationBarDropdownButtonProps {
    accessibleName: string;
}

export function NavigationBarDropdownButton({ accessibleName }: NavigationBarDropdownButtonProps) {
    return (
        <button className={navigationBarItemStyles} aria-label={accessibleName}>
            <ChevronDownIcon />
        </button>
    );
}
