/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */

import { logger } from "@octopusdeploy/logging";
import type { Client } from "../client";
import { Repository } from "../repository";
import type {
    ChannelResource,
    DynamicEnvironmentSettings,
    GetAllProjectSummariesResponse,
    GitCommitResource,
    GitLinkResult,
    GitPersistenceSettings,
    GitRefResource,
    GitTagResource,
    OverviewReleaseBff,
    ProjectDynamicEnvironmentOverviewResponse,
    ProjectIntentsResource,
    ProjectResource,
    ProjectStatusResponse,
    ProjectSummaryResource,
    ReleaseResource,
    ResourceCollection,
    TriggerResource,
    ValidateGitRefV2Response,
} from "../resources";
import type { ConvertProjectToVersionControlledCommand } from "../resources/convertProjectToVersionControlledCommand";
import type {
    ConvertProjectToVersionControlledResponse,
    DeploymentSettingsResource,
    GitBranchResource,
    NewProjectResource,
    ProjectGroupResource,
    ProjectSettingsMetadata,
    ProjectSummary,
    RunbookResource,
    TriggerActionCategory,
    TriggerActionType,
    VersionControlCompatibilityResponse,
} from "../resources/index";
import { HasGitPersistenceSettings, HasVcsProjectResourceLinks } from "../resources/index";
import type { GetInsightsForProjectBffResponse } from "../resources/insightsProjectBffResponse";
import type { MigrateProjectVariablesToGitCommand, MigrateProjectVariablesToGitResponse } from "../resources/migrateProjectVariablesToGitCommand";
import type { MigrateProjectVariablesToGitSummary } from "../resources/migrateProjectVariablesToGitSummary";
import type { GitRef } from "../resources/versionControlledResource";
import { isGitBranch, isGitCommit, isGitTag } from "../resources/versionControlledResource";
import { BasicRepository } from "./basicRepository";
import type { InsightsGranularity, InsightsTimeRange } from "./insightsReportRepository";

export interface ListProjectsArgs {
    skip?: number;
    take?: number;
    partialName?: string;
    clonedFromProjectId?: string;
}

export enum InsightsTenantFilter {
    UntenantedAndAllTenants = "UntenantedAndAllTenants",
    Untenanted = "Untenanted",
    SingleTenant = "SingleTenant",
}

export const UseDefaultBranch = { UseDefaultBranch: true };
type UseDefaultBranch = typeof UseDefaultBranch;
export type SpecifiedGitRef = string;
export type BranchSpecifier = SpecifiedGitRef | UseDefaultBranch;
export interface PreviewProtectedBranchesRequest {
    defaultBranch: string;
    protectedDefaultBranch: boolean;
    protectedBranchNamePatterns: string[];
}

export interface GetInsightsMetricsForProjectRequest extends Record<string, string | undefined> {
    channelId: string;
    environmentId: string;
    tenantId?: string;
    timeRange: InsightsTimeRange;
    granularity: InsightsGranularity;
    tenantFilter?: InsightsTenantFilter;
    timeZone: string;
}

export class ProjectRepository extends BasicRepository<ProjectResource, NewProjectResource> {
    constructor(client: Client) {
        super("Projects", client);
    }

    async modify(resource: ProjectResource, args?: {}): Promise<ProjectResource> {
        const project = await super.modify(resource, args);
        this.client.dispatchEvent({ type: "ProjectModified", project });
        return project;
    }

    getDeployments(project: ProjectResource) {
        return this.client.get(this.client.getLink("Deployments"), { projects: project.Id });
    }

    getDeploymentSettings(project: ProjectResource): Promise<DeploymentSettingsResource> {
        return this.client.get(project.Links["DeploymentSettings"]);
    }

    getReleases(project: ProjectResource, args?: { skip?: number; take?: number; searchByVersion?: string }): Promise<ResourceCollection<ReleaseResource>> {
        return this.client.get<ResourceCollection<ReleaseResource>>(project.Links["Releases"], args!);
    }

    async getBffOverviewReleases(project: ProjectResource): Promise<OverviewReleaseBff[]> {
        const resp = await this.client.get<OverviewReleaseBff[]>("~/bff/spaces/{spaceId}/projects/{projectId}/overview-dashboard-releases", {
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
        return resp;
    }

    getReleaseByVersion(project: ProjectResource, version: string): Promise<ReleaseResource> {
        return this.client.get(project.Links["Releases"], { version });
    }

    list(args?: ListProjectsArgs): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(this.client.getLink("Projects"), { ...args });
    }

    listByGroup(projectGroup: ProjectGroupResource): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(projectGroup.Links["Projects"]);
    }

    getChannels(project: ProjectResource, skip: number = 0, take: number = Repository.takeAll): Promise<ResourceCollection<ChannelResource>> {
        return this.client.get<ResourceCollection<ChannelResource>>(project.Links["Channels"], { skip, take });
    }

    getTriggers(
        project: ProjectResource,
        gitRef: GitRefResource | string | undefined,
        skip?: number,
        take?: number,
        triggerActionType?: TriggerActionType,
        triggerActionCategory?: TriggerActionCategory,
        runbooks?: string[],
        partialName?: string
    ): Promise<ResourceCollection<TriggerResource>> {
        return this.client.get<ResourceCollection<TriggerResource>>(project.Links["Triggers"], { skip, take, gitRef: GetGitRefDetails(gitRef), triggerActionType, triggerActionCategory, runbooks, partialName });
    }

    orderChannels(project: ProjectResource) {
        return this.client.post(project.Links["OrderChannels"]);
    }

    getPulse(projects: ProjectResource[]) {
        const projectIds = projects
            .map((p) => {
                return p.Id;
            })
            .join(",");
        return this.client.get(this.client.getLink("ProjectPulse"), { projectIds });
    }

    getMetadata(project: ProjectResource): Promise<ProjectSettingsMetadata[]> {
        return this.client.get(project.Links["Metadata"], {});
    }

    getRunbooks(project: ProjectResource, args?: { skip?: number; take?: number }): Promise<ResourceCollection<RunbookResource>> {
        return this.client.get<ResourceCollection<RunbookResource>>(project.Links["Runbooks"], args);
    }

    getDynamicEnvironmentSettings(project: ProjectResource): Promise<DynamicEnvironmentSettings> {
        return this.client.get<DynamicEnvironmentSettings>(project.Links["DynamicEnvironmentSettings"]);
    }

    updateDynamicEnvironmentSettings(project: ProjectResource, settings: DynamicEnvironmentSettings): Promise<DynamicEnvironmentSettings> {
        return this.client.post(project.Links["DynamicEnvironmentSettings"], settings).then(() => this.getDynamicEnvironmentSettings(project));
    }

    async summaries(): Promise<ProjectSummaryResource[]> {
        const response = await this.client.get<GetAllProjectSummariesResponse>("~/bff/spaces/{spaceId}/projects/summaries");
        return response.Projects;
    }

    async summariesVersionControlled(): Promise<ProjectSummaryResource[]> {
        const response = await this.client.get<GetAllProjectSummariesResponse>("~/bff/spaces/{spaceId}/projects/summaries", { isVersionControlled: true });
        return response.Projects;
    }

    getSummary(project: ProjectResource, gitRef: GitRefResource | string | undefined): Promise<ProjectSummary> {
        return this.client.get(project.Links["Summary"], { gitRef: GetGitRefDetails(gitRef) });
    }

    getBranch(project: ProjectResource, branch: BranchSpecifier): Promise<GitBranchResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasGitPersistenceSettings(project.PersistenceSettings)) {
            const branchName: string = ShouldUseDefaultBranch(branch) ? project.PersistenceSettings.DefaultBranch : branch;
            return this.client.get(project.Links.Branches, { name: branchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }

    async previewProtectedBranches(project: ProjectResource, request: PreviewProtectedBranchesRequest): Promise<ResourceCollection<GitBranchResource>> {
        if (HasVcsProjectResourceLinks(project.Links) && HasGitPersistenceSettings(project.PersistenceSettings)) {
            const resp = await this.client.post<{ Branches: ResourceCollection<GitBranchResource> }>("~/bff/spaces/{spaceId}/projects/{projectId}/git/preview-protected-branches{?skip,take}", request, {
                spaceId: project.SpaceId,
                projectId: project.Id,
            });
            return resp.Branches;
        }
        throw new Error("Cannot preview protected branches for this project");
    }

    getGitRef(project: ProjectResource, gitRef: GitRef): Promise<GitRefResource> {
        if (isGitBranch(gitRef)) {
            return this.getBranch(project, gitRef);
        } else if (isGitTag(gitRef)) {
            return this.getTag(project, gitRef);
        } else if (isGitCommit(gitRef)) {
            return this.getCommit(project, gitRef);
        } else {
            logger.error("Could not determine the Git ref type of '{GitRef}' on the project '{Project}'.", { GitRef: gitRef, Project: project.Slug });
            throw "Could not determine the Git ref type of '" + gitRef + "' on the project '" + project.Slug + "'.";
        }
    }

    getCommit(project: ProjectResource, hash: string): Promise<GitCommitResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasGitPersistenceSettings(project.PersistenceSettings)) {
            return this.client.get(project.Links.Commits, { hash });
        }
        throw new Error("Cannot retrieve commits from non-VCS projects");
    }

    getTag(project: ProjectResource, tagName: string): Promise<GitTagResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasGitPersistenceSettings(project.PersistenceSettings)) {
            return this.client.get(project.Links.Tags, { name: tagName });
        }
        throw new Error("Cannot retrieve tags from non-VCS projects");
    }

    async tryGetBranch(project: ProjectResource, branch: BranchSpecifier): Promise<GitBranchResource | null> {
        try {
            return await this.getBranch(project, branch);
        } catch (ex) {
            if (ex.StatusCode === 404) {
                return null;
            }
            throw ex;
        }
    }

    getBranches(project: ProjectResource): Promise<ResourceCollection<GitBranchResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Branches);
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }

    getTags(project: ProjectResource): Promise<ResourceCollection<GitTagResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Tags);
        }
        throw new Error("Cannot retrieve tags from non-VCS projects");
    }

    searchTags(project: ProjectResource, partialBranchName: string): Promise<ResourceCollection<GitTagResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Tags, { searchByName: partialBranchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }

    searchBranches(project: ProjectResource, partialBranchName: string): Promise<ResourceCollection<GitBranchResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Branches, { searchByName: partialBranchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }

    async getBranchesFromSettings(project: ProjectResource, settings: GitPersistenceSettings, pagination?: { skip?: number; take?: number }): Promise<ResourceCollection<GitBranchResource>> {
        const response = await this.client.post<{ Branches: ResourceCollection<GitBranchResource> }>(
            `~/bff/spaces/${project.SpaceId}/projects/${project.Id}/git/branches-by-settings{?skip,take}`,
            {
                Settings: settings,
            },
            pagination
        );

        return response.Branches;
    }

    convertToVcs(project: ProjectResource, payload: ConvertProjectToVersionControlledCommand): Promise<ConvertProjectToVersionControlledResponse> {
        return this.client.post<ConvertProjectToVersionControlledResponse>(project.Links.ConvertToGit, payload);
    }

    getMigrateVariablesToGitSummary(project: ProjectResource): Promise<MigrateProjectVariablesToGitSummary> {
        if (project.Links.MigrateVariablesToGit !== undefined) {
            return this.client.get<MigrateProjectVariablesToGitSummary>(project.Links.MigrateVariablesToGit);
        }

        // Could be because it's in the database, the variables have already been migrated, or the feature isn't enabled
        throw new Error("Migrating variables to Git is not available for this project");
    }

    migrateVariablesToGit(project: ProjectResource, payload: MigrateProjectVariablesToGitCommand): Promise<MigrateProjectVariablesToGitResponse> {
        if (project.Links.MigrateVariablesToGit !== undefined) {
            return this.client.post<MigrateProjectVariablesToGitResponse>(project.Links.MigrateVariablesToGit, payload);
        }

        // Could be because it's in the database, the variables have already been migrated, or the feature isn't enabled
        throw new Error("Migrating variables to Git is not available for this project");
    }

    vcsCompatibilityReport(project: ProjectResource): Promise<VersionControlCompatibilityResponse> {
        return this.client.get(project.Links["GitCompatibilityReport"]);
    }

    validateGitRef(project: ProjectResource, gitRef: GitRef): Promise<ValidateGitRefV2Response> {
        return this.client.post(
            "~/bff/spaces/{spaceId}/projects/{projectId}/git/validate/v2",
            { GitRef: gitRef },
            {
                spaceId: project.SpaceId,
                projectId: project.Id,
            }
        );
    }

    getPullRequestLink(project: ProjectResource, gitRef: string): Promise<GitLinkResult> {
        if (HasGitPersistenceSettings(project.PersistenceSettings)) {
            return this.client.get(`~/bff/spaces/${project.SpaceId}/projects/${project.Id}/pull-request-link{?sourceBranch,targetBranch}`, { sourceBranch: gitRef, targetBranch: project.PersistenceSettings.DefaultBranch });
        }
        throw new Error("Cannot retrieve pull-request link from non-VCS projects");
    }

    // TODO: @team-config-as-code - Our project needs a custom "Delete" link that does _not_ include the GitRef in order for us to
    // successfully hit the /projects/{id} DEL endpoint. For EAP, we're out of time and just hacking it into the frontend client.
    del(project: ProjectResource) {
        if (project.IsVersionControlled) {
            // Our "Self" link should currently include the GitRef. If so, and our last path does not look like our projectId, strip it.
            const selfLinkParts = project.Links.Self.split("/");
            if (selfLinkParts[selfLinkParts.length - 1] !== project.Id) {
                selfLinkParts.pop();
            }
            const selfLink = selfLinkParts.join("/");
            return this.client.del(selfLink);
        } else {
            return this.client.del(project.Links.Self);
        }
    }

    markAsStale(project: ProjectResource): Promise<void> {
        return this.client.post(project.Links["RepositoryModified"]);
    }

    getInsightsMetrics(project: ProjectResource, args: GetInsightsMetricsForProjectRequest): Promise<GetInsightsForProjectBffResponse> {
        return this.client.get<GetInsightsForProjectBffResponse>(`~/bff/spaces/{spaceId}/insights/projects/{projectId}{?projectId,channelId,environmentId,tenantId,tenantFilter,timeRange,granularity,timeZone}`, {
            ...args,
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
    }

    getProjectStatus(projectId: string, spaceId: string): Promise<ProjectStatusResponse> {
        return this.client.get<ProjectStatusResponse>("~/bff/spaces/{spaceId}/useronboarding/{projectId}", {
            spaceId,
            projectId,
        });
    }

    getProjectDynamicEnvironmentsOverview(projectId: string, spaceId: string): Promise<ProjectDynamicEnvironmentOverviewResponse> {
        return this.client.get<ProjectDynamicEnvironmentOverviewResponse>("~/bff/spaces/{spaceId}/projects/{projectId}/environments/dynamic/overview", {
            spaceId,
            projectId,
        });
    }

    getProjectIntents(projectSlug: string, spaceId: string): Promise<ProjectIntentsResource | null> {
        return this.client.get<ProjectIntentsResource>("~/bff/spaces/{spaceId}/projects/{projectSlug}/intents", {
            spaceId,
            projectSlug,
        });
    }

    setProjectIntents(projectSlug: string, spaceId: string, projectIntents: ProjectIntentsResource): Promise<void> {
        return this.client.post(
            "~/bff/spaces/{spaceId}/projects/{projectSlug}/intents",
            {
                projectIntents,
            },
            {
                spaceId,
                projectSlug,
            }
        );
    }
}

function GetGitRefDetails(gitRef: GitRefResource | string | undefined): string {
    if (typeof gitRef === "string" || gitRef instanceof String) {
        return gitRef as string;
    } else {
        return gitRef?.CanonicalName as string;
    }
}

export function ShouldUseDefaultBranch(branch: BranchSpecifier): branch is UseDefaultBranch {
    return typeof branch === "object";
}
