import type { Client } from "../client";
import type { GitCredentialResource, GitCredentialUsage } from "../resources";
import { BasicRepository } from "./basicRepository";

export class GitCredentialRepository extends BasicRepository<GitCredentialResource, GitCredentialResource> {
    constructor(client: Client) {
        super("GitCredentials", client);
    }
    usage(gitCredential: GitCredentialResource) {
        return this.client.get<GitCredentialUsage>(gitCredential.Links.Usage);
    }
}
