import type { ConsoleLoggers } from "../logger";
import type { LoggingSink, LogEvent, PropertyValues, LogLevel, PropertyValue } from "../types";
import { isStructuredLogEvent, isLogEventWithPossibleError } from "../types";
import { renderLogMessage } from "../utils";
import { exhaustiveCheck } from "../utils/exhaustiveCheck";

export function createConsoleSink(consoleLoggers: ConsoleLoggers): LoggingSink {
    return {
        receiveLogEvents(logEvents: LogEvent[]) {
            logEvents.forEach((logEvent) => {
                const extraConsoleContext = getContextToIncludeInConsole(logEvent);
                const logger = getConsoleLoggerWithContext(consoleLoggers, logEvent.logLevel, extraConsoleContext);
                if (isStructuredLogEvent(logEvent)) {
                    logger(renderLogMessage(logEvent));
                } else {
                    logger(logEvent.message, ...logEvent.messageArguments);
                }
            });
        },
    };
}

function getContextToIncludeInConsole(logEvent: LogEvent): PropertyValues {
    if (isStructuredLogEvent(logEvent)) {
        // console.error and console.warn automatically includes the current stack.
        // Most of the time this is what you want to see.
        // Sometimes, the stack trace could come from an error raised elsewhere that is passed to the logger.
        // In these cases, there's no way to control the stack trace that the browser renders automatically for you.
        // Instead, we attach this as a property to the log event, so that you don't lose this information.
        return isLogEventWithPossibleError(logEvent) && logEvent.error !== undefined ? { ...logEvent.propertyValues, Error: logEvent.error } : logEvent.propertyValues;
    }
    return logEvent.context;
}

function getConsoleLoggerWithContext(consoleLoggers: ConsoleLoggers, logLevel: LogLevel, propertyValues: PropertyValues): (message: unknown, ...optionalParams: PropertyValue[]) => void {
    const consoleLog = getConsoleLogFunction(consoleLoggers, logLevel);

    return (message, ...optionalParams) => {
        if (Object.keys(propertyValues)) {
            consoleLog(message, ...optionalParams, propertyValues);
        } else {
            consoleLog(message, ...optionalParams);
        }
    };
}

function getConsoleLogFunction(consoleLoggers: ConsoleLoggers, logLevel: LogLevel): typeof console.log {
    switch (logLevel) {
        case "verbose":
            return consoleLoggers.debugConsole.originalConsoleLogger;
        case "debug":
            return consoleLoggers.debugConsole.originalConsoleLogger;
        case "information":
            return consoleLoggers.infoConsole.originalConsoleLogger;
        case "warning":
            return consoleLoggers.warnConsole.originalConsoleLogger;
        case "error":
            return consoleLoggers.errorConsole.originalConsoleLogger;
        case "fatal":
            return consoleLoggers.errorConsole.originalConsoleLogger;
        default:
            exhaustiveCheck(logLevel, "Unhandled log level");
    }
}
