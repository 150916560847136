import type { Client } from "../client";
import type { TaskResource } from "../resources";

export type TelemetryTypes = "performance" | "features";

type DownloadParams = {
    type: TelemetryTypes;
};

type SendParams = {
    type: TelemetryTypes;
};

type RecordPortalOperationTelemetryCommand = {
    source: string;
    operation: string;
    resultsDiscarded: boolean;
    isVersionControlled?: boolean;
    duration: number;
};

export class TelemetryRepository {
    protected client: Client;

    constructor(client: Client) {
        this.client = client;
    }

    download(params: DownloadParams): Promise<void> {
        return this.client.get(this.client.getLink("TelemetryDownload"), params);
    }

    send(params: SendParams): Promise<void> {
        return this.client.get(this.client.getLink("TelemetrySend"), params);
    }

    getLatestTelemetryTask(): Promise<TaskResource> {
        const link = this.client.getLink("TelemetryLastTask");
        return this.client.get(link);
    }

    recordLoadDuration(command: RecordPortalOperationTelemetryCommand) {
        return this.client.post("~/bff/telemetry/recordPortalOperation", command);
    }
}
