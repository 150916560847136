/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { Client } from "../client";
import type { DeploymentPromotionTarget, RunbookRunResource, RunbookRunTemplateResource } from "../resources";
import type { RunbookRunPreviewResource } from "../resources/deploymentPreviewResource";
import type { ResourceCollection } from "../resources/resourceCollection";
import type { RunbookSnapshotResource } from "../resources/runbookSnapshotResource";
import type { VariableSetResource } from "../resources/variableSetResource";
import type { ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";

type GetRunbookRunArgs = ListArgs;

export class RunbookSnapshotRepository extends BasicRepository<RunbookSnapshotResource, RunbookSnapshotResource> {
    constructor(client: Client) {
        super("RunbookSnapshots", client);
    }
    getRunbookRuns(runbookSnapshot: RunbookSnapshotResource, options?: GetRunbookRunArgs): Promise<ResourceCollection<RunbookRunResource>> {
        return this.client.get(runbookSnapshot.Links["RunbookRuns"], options);
    }
    getRunbookRunTemplate(runbookSnapshot: RunbookSnapshotResource): Promise<RunbookRunTemplateResource> {
        return this.client.get(runbookSnapshot.Links["RunbookRunTemplate"]) as Promise<RunbookRunTemplateResource>;
    }
    getRunbookRunPreviewForPromotionTarget(promotionTarget: DeploymentPromotionTarget) {
        return this.client.get<RunbookRunPreviewResource>(promotionTarget.Links["RunbookRunPreview"], { includeDisabledSteps: true });
    }
    snapshotVariables(runbookSnapshot: RunbookSnapshotResource): Promise<RunbookSnapshotResource> {
        return this.client.post(runbookSnapshot.Links["SnapshotVariables"]);
    }
    variables(runbookSnapshot: RunbookSnapshotResource): Promise<VariableSetResource[]> {
        return this.client.get(runbookSnapshot.Links["Variables"]);
    }
}
