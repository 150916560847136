import { css } from "@emotion/css";
import { borderRadius, color } from "@octopusdeploy/design-system-component-tokens";
import React from "react";
import { resetStyles } from "../../utils/resetStyles";
import { XMarkIcon } from "../Icon/XMarkIcon";

interface ClearInputButtonProps {
    onClear: () => void;
    accessibleName: string;
}

export function ClearInputButton({ onClear, accessibleName }: ClearInputButtonProps) {
    return (
        <button className={clearInputButtonStyles} onClick={() => onClear()} aria-label={accessibleName}>
            <XMarkIcon />
        </button>
    );
}

const clearInputButtonStyles = css({
    ...resetStyles.button,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: borderRadius.small,
    outline: "transparent 2px solid",
    color: color.scale.slate[900],

    transitionProperty: "background-color, outline",
    transitionDuration: "0.2s",

    ":focus-visible": {
        outlineColor: color.scale.blue[400],
    },
    ":hover": {
        background: color.scale.slate[200],
    },
});
