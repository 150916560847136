import type { Client } from "../client";
import type { LifecycleResource } from "../resources";
import type { ProjectResource } from "../resources/projectResource";
import { BasicRepository } from "./basicRepository";

export class LifecycleRepository extends BasicRepository<LifecycleResource, LifecycleResource> {
    constructor(client: Client) {
        super("Lifecycles", client);
    }
    preview(lifecycle: LifecycleResource): Promise<LifecycleResource> {
        return this.client.get(lifecycle.Links["Preview"]);
    }
    previews(ids: string[]): Promise<LifecycleResource[]> {
        return this.client.get(this.client.getLink("LifecyclePreviews"), { ids: ids.join(",") });
    }
    projects(lifecycle: LifecycleResource) {
        return this.client.get<ProjectResource[]>(lifecycle.Links["Projects"]);
    }
}
