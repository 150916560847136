import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { css, cx, borderRadius, color, space, textStyles, resetStyles } from "@octopusdeploy/portal-design-system";
import type { LinkElement } from "@octopusdeploy/routing";
import { useOctopusLinkComponent } from "@octopusdeploy/routing";
import type { KeyboardEvent } from "react";
import React from "react";
import { SpaceLogo } from "../SpaceLogo";

interface SpaceListProps {
    spaces: SpaceResource[];
    getSpaceHref: (space: SpaceResource) => string;
}

export interface SpaceListElement {
    focus: () => void;
}

export const SpaceList = React.forwardRef<SpaceListElement, SpaceListProps>(({ spaces, getSpaceHref }, ref) => {
    const listRef = React.useRef<HTMLUListElement>(null);
    const [currentItemIndex, setCurrentItemIndex] = React.useState<number>(0);
    const currentItemElement = React.useRef<LinkElement>(null);

    const Link = useOctopusLinkComponent();

    React.useImperativeHandle(ref, () => ({
        focus: () => {
            currentItemElement.current?.focus();
        },
    }));

    const onKeyDown = (event: KeyboardEvent) => {
        const { key } = event;
        if (key === "ArrowUp" || key === "ArrowDown") {
            event.preventDefault();
            setCurrentItemIndex((i) => {
                const direction = key === "ArrowUp" ? -1 : 1;
                return (i + direction + spaces.length) % spaces.length;
            });
        }
    };

    React.useEffect(() => {
        if (listRef.current?.contains(document.activeElement)) {
            currentItemElement.current?.focus();
        }
    }, [currentItemIndex]);

    React.useEffect(() => {
        setCurrentItemIndex(0);
    }, [spaces]);

    return (
        <ul className={spaceListStyles} aria-label="Spaces" onKeyDown={onKeyDown} ref={listRef}>
            {spaces.map((space, index) => {
                const hasDescription = !!space.Description;
                const styles = [spaceLinkStyles, hasDescription ? spaceLinkTwoLineStyles : spaceLinkSingleLineStyles];

                return (
                    <li key={space.Id}>
                        <Link className={cx(styles)} href={getSpaceHref(space)} tabIndex={currentItemIndex === index ? 0 : -1} ref={currentItemIndex === index ? currentItemElement : undefined}>
                            <div className={spaceListItemLogoContainerStyles}>
                                <SpaceLogo space={space} />
                            </div>
                            <span className={spaceListItemNameStyles}>{space.Name}</span>
                            {hasDescription && <span className={spaceListItemDescriptionStyles}>{space.Description}</span>}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
});
SpaceList.displayName = "SpaceList";

const spaceListStyles = css({
    ...resetStyles.ul,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    paddingTop: space[1],
    paddingBottom: space[6],
    paddingLeft: space[6],
    paddingRight: space[6],
    gap: space[3],
});

const spaceLinkStyles = css({
    ...resetStyles.anchor,
    display: "grid",
    gridTemplateColumns: "min-content 1fr",
    alignItems: "center",
    columnGap: space[4],
    rowGap: space[1],
    padding: space[4],
    borderRadius: borderRadius.large,

    outline: "transparent solid 3px", // outline must be defined to be able to transition

    ":focus-visible": {
        outlineColor: color.scale.blue[400],
    },
    ":hover": {
        background: color.scale.slate[100],
    },
});

const spaceLinkSingleLineStyles = css({
    gridTemplateRows: "1fr",
    gridTemplateAreas: "'image space-name'",
});
const spaceLinkTwoLineStyles = css({
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: "'image space-name' 'image space-description'",
});

const spaceListItemLogoContainerStyles = css({
    gridArea: "image",
    width: "3rem",
    height: "3rem",
    borderRadius: borderRadius.medium,
    border: `${space["1px"]} solid ${color.scale.slate[200]}`,
    padding: space[2],
    background: color.scale.white,
});

const spaceListItemNameStyles = css({
    ...textStyles.body.default.base,
    gridArea: "space-name",
    color: color.scale.grey[900],
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
});

const spaceListItemDescriptionStyles = css({
    ...textStyles.body.default.base,
    gridArea: "space-description",
    color: color.scale.grey[500],
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
});
